import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Divider, Typography, styled } from "@mui/material";

import { useLearnContext } from "./LearnContext";
import CustomButton from "../../Common/CustomButton";
import TubliChatBot from "../../Common/TubliChatBot";
import MoreCourseDetails from "./MoreCourseDetails";
import CourseTasks from "./CourseTasks";
import { logEvent } from "../../../libs/amplitude";
import { userProfile } from "../../../libs/UserProfile";
import {
  ContentItem,
  CoursePresentationPageType,
  CourseSectionItem,
  FilterOptions,
  SectionStates,
} from "../Common/LearningTypes";
import BannerCard from "../BannerCard";
import CustomizeCourseContent from "./CustomizeCourseContent";
import OverlayModal from "../../Common/OverlayModal";
import WarningPopup from "./WarningPopup";
import CourseFeedBack from "./CourseFeedback";
import {
  LearningContentSkeleton,
  MoreLearningContentSkeleton,
} from "../Skeletons/Skeletons";
import CustomToast from "../../Common/CustomToast";
import useCourseSectionSync from "./hooks/useCourseSectionSync";
import useFetchDeepdiveRoadmap from "./hooks/useFetchDeepdiveRoadmap";
import useFetchRoadmapData from "./hooks/useFetchRoadmapData";
import StatusNotification from "../../Common/StatusNotification";
import RenderDeepDiveSection from "./RenderDeepDiveSection";
import {
  ArrowBackOutlined,
  CheckCircleOutlineRounded,
} from "@mui/icons-material";
import CourseLearningCard from "./CourseLearningCard";
import FeedSlider from "../../UserHome/Base/FeedSlider";
import ProgressBar from "../../Common/progressBar";
import { arrowRightSvg, goDeeper, questionLink } from "../../UserHome/Base/SVG";
import TublibotAvatar from "../../Common/TubliChatBot/TublibotAvatar";
import { useChatBotContext } from "../../Context/ChatBotContext";

const CoursePresentation = () => {
  const history = useHistory();

  let { roadmapId, courseId } = useParams<CoursePresentationPageType>();
  const [asideOpen, setAsideOpen] = useState(false);

  const {
    error,
    activeSectionId,
    activeCourse,
    learningProgress,
    loadingState,
    aiQuestions,
    popupStateData,
    deepdiveRoadmapPhases,
    recentInteractedRoadmaps,
    setError,
    setActiveSectionId,
    fetchAiQuestions,
    fetchLearningProgress,
    updateLoadingState,
    setPopupStateData,
    fetchInteractedRoadmapPhases,
    createDeepdiveRoadmap,
  } = useLearnContext();
  const { setChatBotData } = useChatBotContext();
  const secDesc = activeCourse?.sections.get(activeSectionId)?.description;
  const secTitle = activeCourse?.sections.get(activeSectionId)?.title;
  const phaseDesc = activeCourse?.description;
  const phaseTitle = activeCourse?.courseName;

  const queryContext = `Course phase title is, ${phaseTitle}, phase description is,  ${phaseDesc}, course section title is, ${secTitle} and section description is, ${secDesc}`;

  const [filterOpen, setFilterOpen] = useState(false);
  const [alertActive, setAlertActive] = useState(false);

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    contentType: "both",
    timeFrame: 15,
  });

  const updateFilterOptions = <T extends keyof FilterOptions>(
    option: T,
    newValue: FilterOptionValue<T>
  ) => {
    setFilterOptions((prev) => ({ ...prev, [option]: newValue }));
  };

  const [sectionStates, setSectionStates] = useState<SectionStates>({});

  const handleFilter = (filterOptions: FilterOptions) => {
    setFilterOptions(filterOptions);
    setAlertActive(false);
    fetchLearningProgress(activeCourse, filterOptions, undefined, roadmapId);
  };

  const handleBack = () => {
    if (window.history.length > 1) {
      history.goBack();
    }
  };

  const handleCloseToast = () => {
    setPopupStateData((prev) => ({
      ...prev,
      toastData: {
        duration: 6000, // in Ïmiliseconds
        title: "",
        subtitle: "",
        actionMessage: "",
        action: undefined,
      },
      popupMode: "",
    }));
  };

  const shouldLoadMoreResources =
    learningProgress?.progress[activeSectionId] &&
    !loadingState.fetchingLearningContent &&
    !loadingState.fetchingMoreLearningContent &&
    !learningProgress.progress[activeSectionId].isContentPopulated;

  // Refactor: reduce number of useeffects
  useEffect(() => {
    if (learningProgress?.progress.contentFilters) {
      setFilterOptions(learningProgress?.progress.contentFilters);
    }
  }, [learningProgress]);

  useCourseSectionSync(activeSectionId, setActiveSectionId);

  useFetchRoadmapData(roadmapId, courseId);

  const hasAiQuestions =
    aiQuestions &&
    aiQuestions[activeCourse?.id] &&
    aiQuestions[activeCourse?.id][activeSectionId] &&
    aiQuestions[activeCourse?.id][activeSectionId]?.length > 0;

  useEffect(() => {
    if (
      activeCourse &&
      (learningProgress?.roadmapId !== activeCourse.phaseRoadmapId ||
        learningProgress?.pathwayId !== activeCourse.id ||
        !learningProgress?.progress?.[activeSectionId]?.isContentPopulated)
    ) {
      fetchLearningProgress(activeCourse, undefined, undefined, roadmapId);
    }

    // if (!hasAiQuestions) {
    //   fetchAiQuestions(activeCourse);
    // }

    logEvent("8020_Course", userProfile(localStorage).userId(), {
      courseName: activeCourse.courseName,
    });
    // eslint-disable-next-line
  }, [activeSectionId, activeCourse, roadmapId]);

  useEffect(() => {
    if (!sectionStates[activeSectionId]) {
      setSectionStates((prev) => ({
        ...prev,
        [activeSectionId]: {
          showDeepDiveSection: false,
          showCourseTasks: false,
        },
      }));
    }
    // eslint-disable-next-line
  }, [activeSectionId]);

  useEffect(() => {
    if (
      !loadingState.fetchingLearningContent &&
      recentInteractedRoadmaps?.[0]?.phase?.id !== activeCourse?.id
    ) {
      fetchInteractedRoadmapPhases(roadmapId);
    }
    // eslint-disable-next-line
  }, [
    loadingState.fetchingLearningContent,
    activeSectionId,
    activeCourse,
    roadmapId,
  ]);

  useFetchDeepdiveRoadmap(roadmapId);

  const fetchContents =
    !loadingState.fetchingLearningContent &&
    learningProgress?.progress[activeSectionId]?.content;

  const contentItems = (
    learningProgress?.progress[activeSectionId]?.content || []
  ).filter((item): item is ContentItem => Boolean(item));

  const completedItems = contentItems.filter((item) => item.status).length;
  const totalItems = contentItems.length;

  const customConfig = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1.05,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1.03,
        slidesToScroll: 1,
      },
    },
  ];

  const showDeepdiveRoadmap =
    deepdiveRoadmapPhases &&
    deepdiveRoadmapPhases?.roadmap?.phases?.length > 0 &&
    !loadingState.creatingDeepdiveRoadmap &&
    activeCourse?.sections.get(activeSectionId)?.goDeeperRoadmapId &&
    activeCourse?.phaseType !== "godeeper";

  const showDeepDiveSection =
    sectionStates[activeSectionId]?.showDeepDiveSection || showDeepdiveRoadmap;
  const showCourseTasks =
    sectionStates[activeSectionId]?.showCourseTasks || hasAiQuestions;

  const handleItemClick = (item: CourseSectionItem) => {
    if (item.id === 1) {
      setSectionStates((prev) => ({
        ...prev,
        [activeSectionId]: {
          ...prev[activeSectionId],
          showDeepDiveSection: true,
        },
      }));
      createDeepdiveRoadmap(activeCourse.learningPrompt || "", roadmapId);
    } else if (item.id === 2) {
      setSectionStates((prev) => ({
        ...prev,
        [activeSectionId]: {
          ...prev[activeSectionId],
          showCourseTasks: true,
        },
      }));
      fetchAiQuestions(activeCourse);
    } else if (item.id === 3) {
      setChatBotData((prevData) => ({
        ...prevData,
        chatOpen: true,
        chatBotView: "chat view",
      }));
    }
  };
  const getCourseSections = () => {
    if (activeCourse?.phaseType === "godeeper") {
      return [
        {
          text: "Use Tublibot to ask any question about this section",
          icon: <TublibotAvatar />,
          id: 3,
        },
      ];
    }

    const sectionState = sectionStates[activeSectionId] || {
      showDeepDiveSection: false,
      showCourseTasks: false,
    };

    return [
      !(sectionState.showDeepDiveSection || showDeepdiveRoadmap)
        ? {
            text: `Go deeper on "${
              activeCourse?.sections.get(activeSectionId)?.title
            }"`,
            icon: goDeeper,
            id: 1,
          }
        : null,
      !(sectionState.showCourseTasks || hasAiQuestions)
        ? {
            text: `Test your knowledge on "${
              activeCourse?.sections.get(activeSectionId)?.title
            }"`,
            icon: questionLink,
            id: 2,
          }
        : null,
      {
        text: "Use Tublibot to ask any question about this section",
        icon: <TublibotAvatar />,
        id: 3,
      },
    ].filter((item): item is CourseSectionItem => item !== null);
  };

  const handleNext = () => {
    if (activeSectionId !== 4) {
      setActiveSectionId((prev) => prev + 1);
      history.replace(
        `${window.location.pathname}?section=${activeSectionId + 1}`
      );
      logEvent("8020", userProfile(localStorage).userId(), {
        action: "openSection",
        roadmapId: roadmapId,
        phaseId: activeCourse.id,
        sectionId: activeSectionId - 1,
      });
    }
  };

  const handlePrev = () => {
    if (activeSectionId !== 1) {
      setActiveSectionId((prev) => prev - 1);
      history.replace(
        `${window.location.pathname}?section=${activeSectionId - 1}`
      );
      logEvent("8020", userProfile(localStorage).userId(), {
        action: "openSection",
        roadmapId: roadmapId,
        phaseId: courseId,
        sectionId: activeSectionId - 1,
      });
    }
  };

  return (
    <>
      <CustomToast
        open={popupStateData.popupMode === "toast"}
        handleClose={handleCloseToast}
        duration={popupStateData.toastData?.duration}
        title={popupStateData.toastData?.title || ""}
        subtitle={popupStateData.toastData?.subtitle}
      />
      <OverlayModal
        isPreviewOpen={alertActive || popupStateData.isActive || error.error}
      >
        {error.error && (
          <StatusNotification
            status="error"
            statusMessage={error.errorMsg}
            cancelBtnName="Close"
            cancelBtnHandleClick={() =>
              setError({ error: false, errorMsg: "" })
            }
          />
        )}
        {alertActive && (
          <WarningPopup
            setAlertActive={setAlertActive}
            handleClick={() => handleFilter(filterOptions)}
          />
        )}
        {popupStateData.popupMode === "feedback" && <CourseFeedBack />}
      </OverlayModal>

      <SubHeaderWrap>
        <SubHeader>
          <TubliChatBot view="8020" queryContext={queryContext} />
          <CustomButton size="small" type="tertiary" onClick={handleBack}>
            <ArrowBackOutlined style={{ marginRight: 8 }} />
            Back
          </CustomButton>
          <CustomizeCourseContent
            open={filterOpen}
            setOpen={setFilterOpen}
            filterOptions={filterOptions}
            updateFilterOptions={updateFilterOptions}
            setAlertActive={setAlertActive}
          />
        </SubHeader>

        {activeCourse?.phaseType === "godeeper" && (
          <GoDeeperTag marginTop="20px" marginBottom="-8px">
            Deepdive
          </GoDeeperTag>
        )}

        <Box className="sectionSidebar" onClick={() => setAsideOpen(true)}>
          <CustomButton type="tertiary" size="medium">
            Sections
          </CustomButton>
        </Box>
      </SubHeaderWrap>
      <HeaderWrap>
        <BannerCard
          type="course"
          beta={false}
          loading={!activeCourse || activeCourse.id === ""}
          imgUrl={activeCourse?.imageUrl}
          title={activeCourse?.courseName}
          paragraphs={[activeCourse?.description]}
        />
      </HeaderWrap>
      <Divider style={{ margin: "20px 0", backgroundColor: "#888" }} />
      <StyledContentWrap>
        <MainContentBox>
          <LearningContainer>
            <Typography component="h3">
              Section {activeSectionId}:{" "}
              {activeCourse?.sections.get(activeSectionId)?.title}
            </Typography>
            {learningProgress?.progress?.[activeSectionId]?.status && (
              <CustomButton
                size="small"
                preImg={<StyledCheck htmlColor="#1AFF8C" />}
                type="secondary"
              >
                Completed
              </CustomButton>
            )}
          </LearningContainer>
          <StyledDescription>
            {activeCourse?.sections.get(activeSectionId)?.description}
          </StyledDescription>

          {loadingState.fetchingLearningContent && (
            <Box margin="20px 0">
              <LearningContentSkeleton />
            </Box>
          )}

          {fetchContents && (
            <Box className="cardWrap">
              <ul>
                {learningProgress?.progress[
                  activeSectionId
                ]?.learningObjectives?.map((obj, index) => (
                  <li key={index}>
                    <ObjectiveText>{obj.learningObjective}</ObjectiveText>
                  </li>
                ))}
              </ul>

              <LearningCardWrap>
                <ProgressWrap>
                  <ProgressBar
                    max={totalItems}
                    current={completedItems}
                    startText={`${completedItems} of ${totalItems} Marked as Completed`}
                    // progressColor="#FEFEFE"
                    startTextColor="#FEFEFE"
                    height={2}
                    borderRadius={2}
                    margin={2}
                    progressColor="linear-gradient(
                to right,
                #fbda61 0%,
                rgba(255, 90, 205, 0.84) 100%
              )"
                    textRowStyles={{
                      color: "#FEFEFE",
                      "& p": { fontSize: "0.75rem", lineHeight: "1rem" },
                    }}
                  />
                </ProgressWrap>
                <Box sx={{ position: "relative" }}>
                  <FeedSlider slidesToShow={1.3} responsive={customConfig}>
                    {contentItems.map((content, i) => {
                      // Ensure content is defined and has an id
                      if (!content?.id) return null;

                      return (
                        <CourseLearningCard
                          key={content.id}
                          id={content.id}
                          title={content.title}
                          link={content.link}
                          description={content.snippet}
                          thumbnail={content.thumbnail}
                          resourceType={content.resourceType}
                          status={content.status || false} // Default to false if status is undefined
                          bonusContent={
                            learningProgress?.progress[activeSectionId]
                              ?.bonusContent || []
                          }
                          cardIndex={i}
                          roadmapId={roadmapId}
                        />
                      );
                    })}

                    {shouldLoadMoreResources && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                        }}
                      >
                        <CustomButton
                          type="primary"
                          size="medium"
                          onClick={() =>
                            fetchLearningProgress(
                              activeCourse,
                              undefined,
                              (value: boolean) =>
                                updateLoadingState(
                                  "fetchingMoreLearningContent",
                                  value
                                ),
                              roadmapId
                            )
                          }
                        >
                          Load more
                        </CustomButton>
                      </Box>
                    )}

                    {loadingState.fetchingMoreLearningContent && (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <MoreLearningContentSkeleton />
                      </Box>
                    )}
                  </FeedSlider>
                </Box>
              </LearningCardWrap>
            </Box>
          )}

          {/* {!loadingState.fetchingLearningContent &&
            !loadingState.fetchingMoreLearningContent &&
            !learningProgress?.progress?.[activeSectionId] && (
              <MoreResourcesBtnWrap
                sx={{ justifyContent: "flex-start", marginTop: "12px" }}
              >
                <CustomButton
                  postImg={<KeyboardArrowDown />}
                  type="tertiary"
                  size="medium"
                  // onClick={() => window.location.reload()}
                  onClick={() =>
                    fetchLearningProgress(
                      activeCourse,
                      undefined,
                      (value: boolean) =>
                        updateLoadingState(
                          "fetchingMoreLearningContent",
                          value
                        ),
                      roadmapId
                    )
                  }
                >
                  Load learning resources
                </CustomButton>
              </MoreResourcesBtnWrap>
            )} */}

          {showDeepDiveSection && (
            <RenderDeepDiveSection
              deepdivePhase={deepdiveRoadmapPhases}
              roadmapId={roadmapId}
              showDeepdiveRoadmap={showDeepdiveRoadmap}
            />
          )}

          {showCourseTasks && activeCourse?.phaseType !== "godeeper" && (
            <CourseTasks
              roadmapId={roadmapId}
              courseId={courseId}
              activeCourse={activeCourse}
              activeSectionId={activeSectionId}
            />
          )}

          {fetchContents && (
            <SectionWrap>
              <Typography sx={{}}>Next step</Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                {getCourseSections().map((item, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                    key={index}
                    onClick={() => handleItemClick(item)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>{item.icon}</Box>
                      <Box>
                        <Button
                          sx={{
                            fontWeight: 700,
                            fontSize: 18,
                            lineHeight: "30px",
                            background: "transparent",
                            color: "#fefefe",
                            padding: "0px",
                            "&:hover": {
                              borderBottomColor: "#FEFEFE",
                              background: "transparent",
                              textDecoration: "underline",
                              textDecorationColor: "#fefefe",
                            },
                          }}
                          endIcon={arrowRightSvg}
                        >
                          {item.text}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </SectionWrap>
          )}
          {activeCourse?.phaseType !== "godeeper" && fetchContents && (
            <NavWrap>
              {activeSectionId !== 1 && (
                <Box
                  sx={{
                    "&>button": {
                      "&:hover": {
                        background: "#292929 !important",
                      },
                    },
                  }}
                >
                  <CustomButton
                    type="grey"
                    size="medium"
                    disabled={activeSectionId === 1}
                    onClick={handlePrev}
                    style={{
                      background: "transparent",
                      border: "1px solid #414141",
                    }}
                  >
                    Previous section
                  </CustomButton>
                </Box>
              )}

              {activeSectionId !== activeCourse.sections.size && (
                <CustomButton
                  type="grey"
                  disabled={activeSectionId === activeCourse.sections.size}
                  size="medium"
                  onClick={handleNext}
                >
                  Next Section
                </CustomButton>
              )}
            </NavWrap>
          )}
        </MainContentBox>

        <StyledAsideContent open={asideOpen}>
          <MoreCourseDetails
            objectives={
              activeCourse?.sections.get(activeSectionId)?.learningObjectives
            }
            sectionProgress={learningProgress?.progress?.[activeSectionId]}
            closeHandler={() => setAsideOpen(false)}
          />
        </StyledAsideContent>
      </StyledContentWrap>
    </>
  );
};

export default CoursePresentation;

type FilterOptionValue<T extends keyof FilterOptions> = FilterOptions[T];

const SectionWrap = styled(Box)({
  color: "#fefefe",
  marginTop: "20px",
  "> p": {
    marginBottom: "12px",
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "30px",
  },
});

const HeaderWrap = styled(Box)(({ theme }) => ({
  margin: "0 0 22px",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  [theme.breakpoints.down(700)]: {
    margin: "auto",
    marginBottom: 20,
    gap: 20,
    width: "100%",
    alignItems: "center",
  },
}));

const MainContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "67%",
  "& .cardWrap": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // flexWrap: "wrap",
    // justifyContent: "left",
    // gap: 20,
    // margin: "16px 0 28px",
  },
  [theme.breakpoints.down(810)]: {
    width: "62%",
  },
  [theme.breakpoints.down(768)]: {
    width: "100%",
  },
}));

// const MoreResourcesBtnWrap = styled(Box)({
//   display: "flex",
//   flex: 1,
//   justifyContent: "center",
//   alignItems: "center",
// });

const SubHeaderWrap = styled(Box)(({ theme }) => ({
  marginBottom: 28,
  "& .sectionSidebar": {
    display: "none",
    [theme.breakpoints.down(768)]: {
      display: "flex",
    },
  },
  [theme.breakpoints.down(768)]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
}));

const SubHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  justifySelf: "flex-start",
  gap: "24px",
  width: "100%",
  "& button": {
    paddingLeft: "0px !important",
  },
  [theme.breakpoints.down(768)]: {
    alignItems: "flex-start",
    flexDirection: "column",
    gap: "16px",
  },
}));

const LearningContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px 16px",
  flexWrap: "wrap",
  marginBottom: 8,
  "& h3": {
    color: "#FEFEFE",
    fontSize: "1.25rem",
    fontWeight: 700,
    lineHeight: 2,
  },
});

const StyledCheck = styled(CheckCircleOutlineRounded)({
  width: 20,
  height: 20,
});

const StyledDescription = styled(Typography)(({ theme }) => ({
  color: "#FEFEFE",
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.5rem",
  maxWidth: "80%",
  [theme.breakpoints.down(700)]: {
    maxWidth: "100%",
  },
}));

const StyledContentWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: "12px",
  [theme.breakpoints.down(700)]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledAsideContent = styled(Box)<{ open: boolean }>(
  ({ theme, open }) => ({
    position: "sticky",
    // top: 72,
    top: 24,
    width: 297,
    backgroundColor: "#1E1E1E",
    border: "1px solid #292929",
    borderRadius: 20,
    padding: 20,
    [theme.breakpoints.down(768)]: {
      display: open ? "block" : "none",
      zIndex: 99,
      position: "absolute",
      top: 0,
      right: 0,
      width: "calc(100% - 16px)",
      borderRadius: 0,
      height: "100%",
    },
  })
);

const GoDeeperTag = styled(Typography)({
  padding: "4px 8px",
  color: "#FEFEFE",
  fontSize: "0.75rem",
  fontWeight: 500,
  lineHeight: "1rem",
  borderRadius: "20px",
  border: "1px solid transparent",
  alignSelf: "flex-start",
  background:
    "linear-gradient(#1e1e1e, #1e1e1e) padding-box, linear-gradient(109.15deg, #fbda61 0%, rgba(255, 90, 205, 0.84) 86.08%) border-box",
  width: "71px",
});

const ObjectiveText = styled("li")(({ theme }) => ({
  color: "#FEFEFE",
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  flexBasis: "100%",
  maxWidth: "80%",
  marginLeft: "20px",
  [theme.breakpoints.down(768)]: {
    maxWidth: "100%",
  },
}));

const LearningCardWrap = styled(Box)(({ theme }) => ({
  width: "100%",
  margin: "20px 0",
}));

const ProgressWrap = styled(Box)({
  background: "#1E1E1E",
  border: "1px solid #292929",
  borderRadius: "5px",
  width: "219px",
  padding: "6px 12px",
  marginBottom: "12px",
});

const NavWrap = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  gap: 20,
  marginTop: "26px",
  width: "100%",
});
