import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { DocsData } from "./DocsData";
import { RefObject } from "react";

interface DocsContentsProps {
  sectionRefs: { [key: string]: RefObject<HTMLDivElement> };
}

const DocsContents: React.FC<DocsContentsProps> = ({ sectionRefs }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "71%",
        color: "#fefefe",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        [theme.breakpoints.down(1024)]: { width: "100%" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "34px",
            mb: "4px",
          }}
        >
          TOS Developer Agent (Early Access)
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          The <b>Tublian Developer Agent</b> is an AI agent that helps you <b>fix bugs and resolve issues</b> in your open-source or internal projects. It analyzes your codebase and proposes solutions—so you can focus on higher-value work.
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          Once assigned a task, the agent operates independently, reaching out only when it’s stuck or needs clarification.
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          Best part? It runs locally on your machine, giving you full control over your code and data—<b>nothing is shared externally</b>.
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          We're excited to offer this in early access to all our community members.
          You can use the agent to solve up to <b>5 issues/PRs per week</b>—just ping us if you need additional quota!
        </Typography>
      </Box>
      {DocsData.map((m) => (
        <Box ref={sectionRefs[m.id]} key={m.id}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "32px",
              mb: "12px",
            }}
          >
            {m.header}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            {m.content}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default DocsContents;
