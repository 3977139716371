import { ChangeEvent, FC, cloneElement, useEffect, useRef } from "react";
import {
  Box,
  Divider,
  Icon,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  arrowLeftSvg,
  editSvg,
  expandIcon,
  sendSvg,
} from "../../UserHome/Base/SVG";
import TublibotAvatar from "./TublibotAvatar";
import useTubliChatBotStyles from "./tubliChatBotStyles";
import { Close } from "@mui/icons-material";
import {
  ChatBotViewProps,
  fetchTubliChatBotChatHistory,
  handleMouseDown,
  handleSeeMoreClick,
  handleSendMsg,
  minCharCnt,
  minMsgLineCnt,
  suggestedQueries,
} from "./tubliChatBotResources";
import ChatSuggestions from "./ChatSuggestions";
import { useChatBotContext } from "../../Context/ChatBotContext";
import { useTheme } from "@mui/system";
import CopilotEndpoint from "../../../libs/CopilotEndpoint";
import JustLoading from "../JustLoading";

const ChatWindow: FC<ChatBotViewProps> = ({ onClose, view, queryContext }) => {
  const classes = useTubliChatBotStyles();
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:769px)");
  const { chatBotData, setChatBotData } = useChatBotContext();
  const ExpandAndCollapseChat = () => {
    setChatBotData((prevState) => {
      const screenWidth = window.innerWidth;
      let expandedWidth = "calc(100% - 257px)";
      if (screenWidth <= 1024) {
        expandedWidth = "calc(100% - 115px)";
      }
      if (screenWidth <= 769) {
        expandedWidth = "100%";
      }

      return {
        ...prevState,
        chatBoxDimensions: {
          ...prevState.chatBoxDimensions,
          width:
            prevState.chatBoxDimensions.width === 481 ? expandedWidth : 481,
        },
      };
    });
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const chatBotMessage = e.target.value;
    const inputError = {
      error: false,
      errMsg: "",
    };
    setChatBotData((prevData) => ({ ...prevData, inputError, chatBotMessage }));
  };

  const handleNewChat = () => {
    setChatBotData((prevData) => ({
      ...prevData,
      chatBotView: "chat view",
      chatBotMessage: "",
      currentConversationId: "",
      messagesList: [],
    }));
  };

  const handleHistoryClick = () => {
    fetchTubliChatBotChatHistory(setChatBotData);
    setChatBotData((prevData) => ({
      ...prevData,
      chatBotView: "history view",
    }));
  };

  const getSuggestedQueriesAndPrompt = async () => {
    if (chatBotData?.messagesList?.length === 0) {
      setChatBotData((prevData) => ({
        ...prevData,
        loadingQueries: true,
      }));
    }
    const response = await CopilotEndpoint.generateSuggestedQueriesAndPrompt(
      queryContext
    );

    if (view === "8020") {
      setChatBotData((prevData) => ({
        ...prevData,
        suggestedQueries: response.prompts,
        tip: response.tip,
      }));
    } else {
      setChatBotData((prevData) => ({
        ...prevData,
        suggestedQueries: suggestedQueries,
      }));
    }
    setChatBotData((prevData) => ({
      ...prevData,
      loadingQueries: false,
    }));
  };

  useEffect(() => {
    getSuggestedQueriesAndPrompt();
    // eslint-disable-next-line
  }, [queryContext]);

  useEffect(() => {
    // Scroll to the bottom when a message is sent and a reply is loading
    if (chatBotData?.messagesList?.length > 0) {
      chatBodyRef.current?.scrollTo(0, chatBodyRef.current.scrollHeight);
    }
  }, [chatBotData?.messagesList]);

  return (
    <Paper className={classes.chatWindowPaper}>
      <Box
        className={classes.leftResizeHandle}
        onMouseDown={(e: React.MouseEvent<HTMLDivElement>) =>
          handleMouseDown(e, chatBotData, setChatBotData)
        }
      />
      <Box className="chatHeader">
        <Box>
          {chatBotData?.chatBotHistory?.length > 0 && (
            <Box
              style={{ marginRight: 9, cursor: "pointer" }}
              onClick={handleHistoryClick}
            >
              {arrowLeftSvg}
            </Box>
          )}
          <TublibotAvatar size={32} />
          <Typography>Tublibot</Typography>
        </Box>
        <Box>
          {!isSmallScreen && (
            <Box
              style={{
                marginRight: "8px",
                cursor: "pointer",
                color: "#FEFEFE",
              }}
              onClick={ExpandAndCollapseChat}
            >
              {expandIcon}
            </Box>
          )}
          <Box
            style={{
              marginRight: "8px",
              cursor: "pointer",
              color: "#FEFEFE",
            }}
            onClick={handleNewChat}
          >
            {editSvg}
          </Box>
          <Close
            style={{
              cursor: "pointer",
            }}
            htmlColor="#FDD649"
            onClick={onClose}
          />
        </Box>
      </Box>

      <Divider className="chatDivider" />

      <Box
        sx={{
          maxWidth: "808px",
          width: "100%",
          marginInline: "auto",
          overflowY: "auto",
          height: "100%",
          overflowX: "hidden",
          [theme.breakpoints.down(1200)]: { maxWidth: "674px" },
          [theme.breakpoints.down(1025)]: { maxWidth: "658px" },
        }}
      >
        {chatBotData?.loadingQueries && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <JustLoading />
          </Box>
        )}
        {chatBotData?.messagesList?.length === 0 &&
          !chatBotData?.loadingQueries && (
            <Box
              sx={{
                padding: "0 20px",
              }}
            >
              <Box>
                <Typography
                  style={{
                    fontSize: "36px",
                    fontWeight: 700,
                    lineHeight: "52px",
                    margin: "auto",
                    width: "100%",
                    textAlign: "center",
                    padding: "100px 0 20px 0",
                  }}
                >
                  Hello! How can I help you today?
                </Typography>
              </Box>

              <Box className="chatFooter">
                <TextField
                  autoComplete="off"
                  variant="standard"
                  fullWidth
                  InputProps={{ disableUnderline: true, id: "chatBotMessage" }}
                  placeholder={chatBotData.tip || "Ask Anything"}
                  value={chatBotData?.chatBotMessage}
                  onChange={handleInputChange}
                  error={chatBotData?.inputError?.error}
                  label={chatBotData?.inputError?.errMsg || ""}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSendMsg(chatBotData, setChatBotData, view);
                    }
                  }}
                />
                <Icon
                  onClick={() => handleSendMsg(chatBotData, setChatBotData, view)}
                  style={{ cursor: "pointer", marginLeft: "auto" }}
                >
                  {cloneElement(sendSvg, {
                    style:
                      !chatBotData.chatBotMessage ||
                      chatBotData.chatBotMessage.length < minCharCnt
                        ? { color: "#707070" }
                        : { color: "#FDD649" },
                  })}
                </Icon>
              </Box>
              <ChatSuggestions view={view}/>
            </Box>
          )}

        <div className="chatBody" ref={chatBodyRef}>
          {chatBotData?.messagesList?.map((message, i) => (
            <Box
              key={i}
              className={
                message.isLoading
                  ? "isLoadingMsgsWrap"
                  : message.type === "outgoing"
                  ? "outgoingMsgsWrap"
                  : "incomingMsgsWrap"
              }
            >
              {(message.type === "incoming" || message.type === "error") && (
                <TublibotAvatar size={32} />
              )}
              <Box style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {message.type === "incoming" && (
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      lineHeight: "1.5rem",
                      color: "#fefefe",
                    }}
                  >
                    Tublibot
                  </Typography>
                )}
                {message.type === "outgoing" && (
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      lineHeight: "1.5rem",
                      color: "#fefefe",
                    }}
                  >
                    You
                  </Typography>
                )}
                <Box
                  key={i}
                  className={
                    message.type === "outgoing"
                      ? classes.outgoingMessage
                      : classes.incomingMessage
                  }
                >
                  {message.isLoading ? (
                    <Box className={classes.typingIndicator}>
                      <span className={classes.dot}></span>
                      <span className={classes.dot}></span>
                      <span className={classes.dot}></span>
                    </Box>
                  ) : (
                    <>
                      {message.isTruncated && message.type !== "outgoing" ? (
                        <>
                          {Array.isArray(message.text)
                            ? message.text.map((line: string, i: number) => {
                                if (line.startsWith("- ")) {
                                  return <li key={i}>{line.substring(2)}</li>;
                                } else if (line.trim() !== "") {
                                  return (
                                    <Typography key={i}>{line}</Typography>
                                  );
                                }
                                return null;
                              })
                            : typeof message.text === "string" && (
                                <Typography>{message.text}</Typography>
                              )}
                        </>
                      ) : message.text.length > minMsgLineCnt &&
                        message.type !== "outgoing" ? (
                        <>
                          {message.text.map((line: string, i: number) => {
                            if (line.startsWith("- ")) {
                              return <li key={i}>{line.substring(2)}</li>;
                            } else if (line.trim() !== "") {
                              return <Typography key={i}>{line}</Typography>;
                            }
                            return null;
                          })}
                          <button
                            onClick={() =>
                              handleSeeMoreClick(i, setChatBotData)
                            }
                            className={classes.seeMoreButton}
                          >
                            See Less
                          </button>
                        </>
                      ) : (
                        <Typography className={message.type}>
                          {message.text}
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Box>

              {message.type === "outgoing" && (
                <TublibotAvatar type="user" size={32} />
              )}
            </Box>
          ))}
        </div>
      </Box>
      {chatBotData?.messagesList?.length > 0 && (
        <Box sx={{ padding: "0 20px" }}>
          <Box className="chatFooter2">
            <TextField
              autoComplete="off"
              variant="standard"
              fullWidth
              InputProps={{ disableUnderline: true, id: "chatBotMessage" }}
              placeholder="Chat with Tublibot"
              value={chatBotData?.chatBotMessage}
              onChange={handleInputChange}
              error={chatBotData?.inputError?.error}
              label={chatBotData?.inputError?.errMsg || ""}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSendMsg(chatBotData, setChatBotData, view);
                }
              }}
            />
            <Icon
              onClick={() => handleSendMsg(chatBotData, setChatBotData, view)}
              style={{ cursor: "pointer", marginLeft: "auto" }}
            >
              {cloneElement(sendSvg, {
                style:
                  !chatBotData.chatBotMessage ||
                  chatBotData.chatBotMessage.length < minCharCnt
                    ? { color: "#707070" }
                    : { color: "#FDD649" },
              })}
            </Icon>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default ChatWindow;
