import { Box, Typography } from "@mui/material";
import useTubliChatBotStyles from "./tubliChatBotStyles";
import { handleSendMsg, suggestedQueries } from "./tubliChatBotResources";
import { useChatBotContext } from "../../Context/ChatBotContext";

const ChatSuggestions = ({ view }: { view: string }) => {
  const classes = useTubliChatBotStyles();
  const { setChatBotData, chatBotData } = useChatBotContext();

  const handleClickSuggestion = (chatBotMessage: string) => {
    const inputError = {
      error: false,
      errMsg: "",
    };
    setChatBotData((prevData) => {
      const updatedData = {
        ...prevData,
        inputError,
        chatBotMessage,
      };

      handleSendMsg(updatedData, setChatBotData, view);
      return updatedData;
    });
  };

  return (
    <Box className={classes.chatSuggestions}>
      <Box>
        <Box className="suggestedQueriesWrap">
          {(chatBotData.suggestedQueries || suggestedQueries).map((s, i) => (
            <Box
              key={i}
              className="suggestedQueries"
              onClick={() => handleClickSuggestion(s)}
            >
              <Typography>{s}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatSuggestions;
