import useTubliChatBotStyles from "./tubliChatBotStyles";
import ChatIdlePopup from "./ChatIdlePopup";
import { ChatBotViewProps } from "./tubliChatBotResources";
import ChatWindow from "./ChatWindow";
import ChatHistory from "./ChatHistory";
import { ClickAwayListener, Paper } from "@mui/material";
import { useChatBotContext } from "../../Context/ChatBotContext";

const RenderChatView = ({ onClose, view, queryContext }: ChatBotViewProps) => {
  const { chatBotData } = useChatBotContext();

  switch (chatBotData?.chatBotView) {
    case "idle popup":
      return <ChatIdlePopup onClose={onClose} />;
    case "chat view":
      return (
        <ChatWindow onClose={onClose} view={view} queryContext={queryContext} />
      );
    case "history view":
      return <ChatHistory onClose={onClose} />;
    default:
      return null;
  }
};

const ChatBotView = ({ onClose, view, queryContext }: ChatBotViewProps) => {
  const classes = useTubliChatBotStyles();
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Paper className={classes.chatWindowPopper}>
        <RenderChatView
          onClose={onClose}
          view={view}
          queryContext={queryContext}
        />
      </Paper>
    </ClickAwayListener>
  );
};

export default ChatBotView;
