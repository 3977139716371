import { FC } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import {
  IOnClose,
  handleMouseDown,
  handleResumeChat,
} from "./tubliChatBotResources";
import { useChatBotContext } from "../../Context/ChatBotContext";
import useTubliChatBotStyles from "./tubliChatBotStyles";
import TublibotAvatar from "./TublibotAvatar";
import Spinner from "../Spinner";
import { Close } from "@mui/icons-material";
import { arrowLeftSvg, editSvg, expandIcon } from "../../UserHome/Base/SVG";

const ChatHistory: FC<IOnClose> = ({ onClose }) => {
  const classes = useTubliChatBotStyles();
  const { chatBotData, setChatBotData } = useChatBotContext();

  const handleNewChat = () => {
    setChatBotData((prevData) => ({
      ...prevData,
      chatBotView: "chat view",
      chatBotMessage: "",
      currentConversationId: "",
      messagesList: [],
    }));
  };

  const handleBackToCurrentChat = () => {
    setChatBotData((prevData) => ({
      ...prevData,
      chatBotView: "chat view",
    }));
  };

  const ExpandAndCollapseChat = () => {
    setChatBotData((prevState) => ({
      ...prevState,
      chatBoxDimensions: {
        ...prevState.chatBoxDimensions,
        width:
          prevState.chatBoxDimensions.width === 481
            ? "calc(100% - 257px)"
            : 481,
      },
    }));
  };

  return (
    <Paper className={classes.chatWindowPaper}>
      <Box
        className={classes.leftResizeHandle}
        onMouseDown={(e: React.MouseEvent<HTMLDivElement>) =>
          handleMouseDown(e, chatBotData, setChatBotData)
        }
      />
      <>
        <Box className="chatHeader">
          <Box>
            {chatBotData?.messagesList && (
              <Box
                style={{ marginRight: 9, cursor: "pointer" }}
                onClick={handleBackToCurrentChat}
              >
                {arrowLeftSvg}
              </Box>
            )}
            <Typography>Chat History</Typography>
          </Box>

          <Box>
            <Box
              style={{
                marginRight: "8px",
                cursor: "pointer",
                color: "#FEFEFE",
              }}
              onClick={ExpandAndCollapseChat}
            >
              {expandIcon}
            </Box>
            <Box
              style={{ marginRight: 26, cursor: "pointer" }}
              onClick={handleNewChat}
            >
              {editSvg}
            </Box>
            <Close
              style={{ cursor: "pointer" }}
              htmlColor="#FDD649"
              onClick={onClose}
            />
          </Box>
        </Box>
        <Divider className="chatDivider" />

        <Box className="chatHistoryBody">
          {chatBotData?.chatBotHistory?.map((h, i) => (
            <Box
              className="chatHistoryWrap"
              key={i}
              onClick={() => handleResumeChat(h, setChatBotData)}
            >
              <Box className="chatHistoryCard">
                <TublibotAvatar size={32} />
                <Box>
                  <Box className="details">
                    <Typography>Tublibot</Typography>
                    {/* <Typography>11 mins ago</Typography> */}
                  </Box>
                  <Typography>{h?.queries && h.queries[0]}</Typography>
                </Box>
              </Box>
              <Divider style={{ background: "#414141" }} />
            </Box>
          ))}
        </Box>
        {chatBotData.loadingHistory && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </Box>
        )}
      </>
    </Paper>
  );
};

export default ChatHistory;
