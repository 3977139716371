import Bugsnag from "@bugsnag/js";
import ProfileEndpoint from "../../../../libs/ProfileEndpoint";
import { userProfile } from "../../../../libs/UserProfile";

const baseUrl = process.env.REACT_APP_COPILOT_API_BASE;

const gptCall = async (uri, payload, retries = 2) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    for (let i = 0; i < retries; i++) {
        try {
            const response = await fetch(`${baseUrl}/${uri}`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(payload)
            });
            return response.json();
        } catch (error) {
            Bugsnag.notify(error);
            console.error(`API call failed (${i + 1}/${retries}): ${error}`);
        }
    }

    throw new Error(`API call failed after ${retries} retries`);
}


export const analyzeJob = async (jd) => {
    const userCache = userProfile(localStorage);
    const developerBio = await ProfileEndpoint.getDeveloperProfilePrompt(userCache.userId());
    const response = await gptCall("developer/playground/analyze", {
        jd: jd,
        profilePrompt: developerBio.prompt
    });
    return response;
}

const makeGptStreamingCall = async (uri, payload, onChunk, onFinished, onError) => {
    try {
        const response = await fetch(`${baseUrl}/${uri}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        });
        const decoder = new TextDecoder();
        const reader = response.body.getReader();
        let chunks = "";
        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                onFinished(chunks);
                break;
            }
            const result = decoder.decode(value);
            chunks += result;
            onChunk(chunks)
        }
    } catch (error) {
        Bugsnag.notify(error);
        onError(error);
    }
}

export const submitAnswer = async (formData) => {
    const response = await fetch(`${baseUrl}/developer/playground/jd/streaming/audio/answer`, {
        method: "POST",
        body: formData,
    });
    const data = await response.json();
    return data;
}

export const submitTextAnswer = async (answer, question) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    const payload = {
        question,
        answer,
    }

    const response = await fetch(`${baseUrl}/pathway/learning/validate/answer`, {
        method: "POST",
        headers,
        body: JSON.stringify(payload),
    });

    // console.log("🚀 ~ submitTextAnswer ~ response:", response)
    const data = await response.json();
    return data;
}

export const fetchJdSummaryStreaming = async (jd, onChunk, onFinished, onError) => {
    makeGptStreamingCall("developer/playground/jd/streaming/summary", { jd: jd }, onChunk, onFinished, onError);
}

export const fetchMockInterviewStreaming = async (jd, onChunk, onFinished, onError) => {
    makeGptStreamingCall("developer/playground/jd/streaming/mock/interview/v2", { jd: jd }, onChunk, onFinished, onError);
}

export const fetchAnswer = async (question) => {
    const response = await gptCall("developer/playground/jd/suggested/answer", {
        question: question
    });
    return response;
}

export const recommendProjects = async (jd) => {
    const response = await gptCall("project/search", {
        query: jd
    });
    return response;
}

export const fetchCoverLetter = async (jd) => {
    const userCache = userProfile(localStorage);
    const developerBio = await ProfileEndpoint.getDeveloperProfilePrompt(userCache.userId());
    const response = await gptCall("developer/playground/coverletter", {
        jd: jd,
        profilePrompt: developerBio.prompt
    });
    return response;
}

export const generateTailoredResumeSummary = async (jd, sample_text) => {
    const userCache = userProfile(localStorage);
    const developerBio = await ProfileEndpoint.getDeveloperProfilePrompt(userCache.userId());
    const response = await gptCall("developer/playground/jd/resume/summary", {
        jd: jd,
        profilePrompt: developerBio.prompt,
        sample_text: sample_text
    });
    return response;
}

export const generateTailoredResumeWorkExperience = async (
  profilePrompt,
  jd,
  experiences
) => {
  const response = await gptCall(
    "developer/playground/jd/resume/customize/experience",
    {
      profilePrompt: profilePrompt,
      jd: jd,
      experiences: experiences,
    }
  );
//   console.log("customized experienc 1", response);
  return response;
};

export const generatePersonalizedWritingStyle = async (
   writingStyle
  ) => {
    const profilePrompt = userProfile(localStorage).profilePrompt();
    const response = await gptCall(
      "developer/playground/determine/writing/style",
      {
        userPrompt: profilePrompt,
        samples: writingStyle
      }
    );
    return response;
  };

export const fetchLinkedinDM = async (jd) => {
    const userCache = userProfile(localStorage);
    const developerBio = await ProfileEndpoint.getDeveloperProfilePrompt(userCache.userId());
    const response = await gptCall("developer/playground/linkedin/dm", {
        jd: jd,
        profilePrompt: developerBio.prompt
    });
    return response;
}
