import tublianFTFHackChallenge from "../../../images/challenges/techfest-tublian.jpg";

export const challengeData = (challengeId: string, userId: string) => {
  if (challengeId === "101") return createPromptEngineeringChallenge(userId);
  if (challengeId === "202") return createFrontendChallenge(userId);
  if (challengeId === "303") return createRagLLMChallenge(userId);
  if (challengeId === "304") return createTechnicalWritingChallenge(userId);
  if (challengeId === "203") {
    const learningPrompt = "I want to learn how to build pixel-perfect web-based dashboard applications using Figma design. Focus on Figma for frontend developers, UI/UX tips, and building applications using MVVM pattern."
    return createFrontendChallenge203(userId, encodeURIComponent(learningPrompt.trim()));
  }
  if (challengeId === "204") {
    const learningPrompt = "I want to learn how to build pixel-perfect web-based dashboard applications using Figma design. Focus on Figma for frontend developers, UI/UX tips, and building applications using MVVM pattern."
    return createFrontendChallenge204(userId, encodeURIComponent(learningPrompt.trim()));
  }
  if (challengeId === "205") {
    return createFrontendChallenge205(userId);
  }
  if (challengeId === "601") {
    // "I want to learn how to build pixel-perfect web-based dashboard applications using Figma design. Focus on Figma for frontend developers, UI/UX tips, and building applications using MVVM pattern."
    return createChallenge601(userId);
  }
  if (challengeId === "602") {
    // "I want to learn how to build pixel-perfect web-based dashboard applications using Figma design. Focus on Figma for frontend developers, UI/UX tips, and building applications using MVVM pattern."
    return createChallenge602(userId);
  }
  if (challengeId === "603") {
    const learningPrompt = "Learn how to build RAG application using LangChain. Fundamentals of indexing, vector store and retrieval. The application will be a chat interface on top of corpus of data extracted from website."
    return createDevDocGenie(userId, learningPrompt);
  }
  if (challengeId === "604") {
    const learningPrompt = "Build customer service assistant using AWS Bedrock models and Amazon Q Developer"
    return createTechFestChallenge(userId, learningPrompt);
  }
  if (challengeId === "605") {
    const learningPrompt = "Build a frontend application using ReactJs and Material UI";
    return createCertificateGenerator(userId, learningPrompt);
  }
  if (challengeId === "606") {
    const learningPrompt = "Build a frontend application using ReactJs and Material UI";
    return create8020Transcriber(userId, learningPrompt);
  }
};

const createChallenge601 = (userId: string) => {
  return {
    title: "Build an AI ChatBot",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 51,
    duration: "2 week(s)",
    start: "20th May - 9 am EST",
    end: "31st May - 6:00 pm EST",
    submissionLinkText: "Share your github repo",
    submissionLinkValidator: (link: string) => {
      var regex = /^https:\/\/github\.com\/[a-zA-Z0-9]+\/[a-zA-Z0-9_.-]+\/?$/;
      return !!link.match(regex);
    },
    body: [
      {
        header: "Introduction",
        body: "Welcome, Tublian developers! This challenge is the first task of the Tublian AI Internship, and also an opportunity to hone your programming skills, showcase your expertise, and earn StreetCred."
      },
      {
        header: "Why Participate?",
        body: `
        Learn how to build applications on top of Large Language Models. In this challenge, you will learn about using cloud-based AI services and Llama3, the most popular open-source LLM.
        <br/>
        <br/>
        <b><u>This is a compulsory task for the participants of the Tublian AI Internship.</u></b>
        `
      },
      {
        header: "Objective: Build an AI Tutor",
        body: `
        Imagine having your own personal AI tutor that can answer your questions, explain machine learning algorithms, and guide you through your learning journey.
        <br/><br/>
        Your objective is to build a command-line chatbot where users can type in questions and get answers. (No UI interface is required).
        <br/>
        <br/>
        <img src='https://tublian-newsletter-assets.s3.amazonaws.com/chat-interface.png' width='90%' style="border: 5px solid #555;"/>
        `
      },
      {
        header: "Requirements",
        body: `
        Build your application using JavaScript, Python, or any language of your choice as long as you meet the objective above.
        <br/>
        <br/>
        To keep things simple, we are going to use GroqCloud and Llama3. These tools will allow us to quickly build our application without setup headaches.
        <br/><br/>
        Groq is a hardware/software platform to build GenAI applications, and it's free for developers.
        Llama3 is an open-source Large Language Model. Learn more about it here.
        <br/><br/>
        <b>Steps to get started:</b>
        <br/>
        <ul>
            <li>Create an account on <a href="https://console.groq.com/login" target="_blank">GroqCloud</a></li>
            <li>Generate an API key <a href="https://console.groq.com/keys" target="_blank">here<a/></li>
            <li><a href="https://console.groq.com/docs/quickstart" target="_blank">Quickstart</a> guide to install necessary dependencies.</li>
        </ul>
        <br/><br/>
        For Python developers, here is a template GitHub project configured with all the dependencies. Feel free use it.
        <a href="https://github.com/Tublian/groq-llama3-template" target="_blank">Python Template Project</a>
               `,
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge will last 2 weeks. Get started today!"
      },
    ],
    resources: [],
    additionalResources: [
    ],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
}

const createChallenge602 = (userId: string) => {
  return {
    title: "Build an AI SQL Agent (Capstone Project)",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 112,
    duration: "2 week(s)",
    firstPrize: "$100 gift card",
    secondPrize: "$75 gift card",
    thirdPrize: "$50 gift card",
    start: "4th June - 9 am EST",
    end: "21st June - 6:00 pm EST",
    submissionLinkText: "Share your github repo",
    submissionLinkValidator: (link: string) => {
      var regex = /^https:\/\/github\.com\/[a-zA-Z0-9]+\/[a-zA-Z0-9_.-]+\/?$/;
      return !!link.match(regex);
    },
    body: [
      {
        header: "Introduction",
        body: "Welcome, Tublian developers! This is a Capstone project for Tublian AI Internship, and also an opportunity to hone your programming skills, showcase your expertise, and earn StreetCred."
      },
      {
        header: "Why Participate?",
        body: `
        Learn how to build applications on top of Large Language Models. In this challenge, you will learn about using cloud-based AI services and Llama3, the most popular open-source LLM.
        `
      },
      {
        header: "Objective: Build an AI SQL Agent",
        body: `
        Create an LLM system that can query private structured data (SQL database). Typically, LLMs handle unstructured data like text, images, and videos. In this challenge, we'll push the boundaries of LLMs to create a helpful agent for our non-technical analysts to query an SQL database without needing SQL knowledge.
        <br/><br/>
        Imagine an LLM-powered Q&A agent that understands plain English questions, converts them into SQL queries, retrieves the data, and presents the results in plain English.
        Your objective is to build a command-line agent where users can type in questions and get answers from an SQL database. (No UI interface is required).
        <br/>
        <br/>
        Your task is to build a command-line agent where users can type in questions and get answers from an SQL database. No UI is needed.
        <img src='https://tublian-newsletter-assets.s3.amazonaws.com/sql-agent.jpg' width='90%' style="border: 5px solid #555;"/>
        <br/>
        At a high-level, the steps of these systems are:
        <ul>
          <li>Convert question to DSL query: Model converts user input to a SQL query.</li>
          <li>Execute SQL query: Execute the query.</li>
          <li>Answer the question: Model responds to user input using the query results.</li>
        </ul>
        `
      },
      {
        header: "Requirements",
        body: `
        Build your application using JavaScript, Python, or any language of your choice as long as you meet the objective above.
        <br/>
        <br/>
        To keep things simple, we are going to use GroqCloud, Llama3 and Langchain. These tools will allow us to quickly build our application without setup headaches.
        <br/><br/>
        Groq is a hardware/software platform to build GenAI applications, and it's free for developers.
        Llama3 is an open-source Large Language Model. Learn more about it here.
        <br/><br/>
        <b>Steps to get started:</b>
        <br/>
        <ul>
            <li>Create an account on <a href="https://console.groq.com/login" target="_blank">GroqCloud</a></li>
            <li>Generate an API key <a href="https://console.groq.com/keys" target="_blank">here<a/></li>
            <li><a href="https://console.groq.com/docs/quickstart" target="_blank">Quickstart</a> guide to install necessary dependencies.</li>
        </ul>
        <b>Setup SQLite</b>
        <br/>
        We will use an SQLite database for this challenge, but feel free to use any database you prefer.
        <br/>
        <ul>
            <li>Download and install SQLite from the official <a href="https://sqlite.org/download.html">SQLlite website</a></li>
            <li>Download Microsoft Northwind Sample Database. You can download it from <a href="https://github.com/jpwhite3/northwind-SQLite3">here</a></li>
            <li>Populate Your SQLite Database with Northwind Data.</li>
            <li>Verify the data by running a simple query: 'SELECT name FROM sqlite_master WHERE type='table';</li>
        </ul>

        <br/><br/>
               `,
        emphasized: true,
      },
      {
        header: "Hints/Recommendations",
        body: `

        To incorporate reasoning and interaction capabilities in our agent, we can use ReAct prompting. Here's a concise guide on how to integrate ReAct logic using Langchain, a powerful tool for building such agents.
        <br/>
        <br/>
        <div>1. ReAct Prompting</div>
        <br/>
        ReAct (Reasoning and Acting) prompting enables the agent to think step-by-step, reason through problems, and interact with external tools (like databases). This helps in building more intelligent and interactive agents.
        <a href="https://www.promptingguide.ai/techniques/react" target="_blank"> Learn more</a>
        <br/><br/>
        Here is an example of how to build ReAct logic to an agent using <a href="https://python.langchain.com/v0.1/docs/modules/agents/agent_types/react/" target="_blank">Langchain.</a>
        <br/><br/>
        <div>2. Groq/Langchain</div>
        <br/>
        Langchain already provides nice <a href="https://python.langchain.com/v0.2/docs/integrations/chat/groq/" target="_blank">integration with Groq</a>. All you need is your API key.


               `,
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge will last 2 weeks. Get started today!"
      },
    ],
    resources: [],
    additionalResources: [],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
}

const createFrontendChallenge204 = (userId: string, learningPrompt: string) => {
  return {
    title: "Frontend Challenge",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 5,
    firstPrize: "Special Prize",
    secondPrize: "Tublian Merchandize",
    // thirdPrize: "Tublian Merchandize",
    prizeImage: "https://tublian-newsletter-assets.s3.amazonaws.com/tublian-tshirt-merchandize.png",
    duration: "4 week(s)",
    start: "26th Apr - 9 am EST",
    end: "31st May - 6:00 pm EST",
    submissionLinkText: "Share the link to the hosted app",
    submissionLinkValidator: (link: string) => {
      return true;
    },
    body: [
      {
        header: "Introduction",
        body: "Welcome, Tublian front-end developers! This challenge is your opportunity to hone your programming skills, showcase your expertise, and earn Streetcred."
      },
      {
        header: "Why Participate?",
        body: "Tublian challenges offer real-world software development experience. In the professional sphere, teams consist of project managers, designers, QA testers, backend developers, and front-end developers like you.<br/><br/>By participating, you'll gain insight into the frontend development workflow used globally, including at Tublian, a skill in high demand for both full-time positions and <a href='https://www.fiverr.com/gigs/figma-to-html'>freelancing</a>."
      },
      {
        header: "Objective: Create a Developer Community Dashboard",
        body: `As a member of the TublianX web development team, your task is to develop a new dashboard for our developer community.
               The team's designer has provided the Figma design, which you can find here: <br><br/>
               <a href='https://www.figma.com/file/zWy8WJdf4ORgnMWhJ70i5u/Tublian-Challenge?type=design&node-id=0-1&mode=design&t=wuYMUN0RSh8owddO-0' target='_blank'>
               <img src='https://www.figma.com/file/zWy8WJdf4ORgnMWhJ70i5u/thumbnail?ver=thumbnails/9d140ff7-c5fc-4da1-a4d7-701c2f895b3e' width='50%'/>
               </a>
               <br/>
               <a href='https://www.figma.com/file/zWy8WJdf4ORgnMWhJ70i5u/Tublian-Challenge?type=design&node-id=0-1&mode=design&t=wuYMUN0RSh8owddO-0' target='_blank' style='font-size: 20px'>Click here to view the Figma Design</a>
               <br/><br/>
               Your mission is to implement the front end of this application, transforming the design into a pixel perfect functional interface.`
      },
      {
        header: "Requirements",
        body: `<ul>
                  <li><b>Tech Stack Selection:</b> Choose your preferred tech stack. Options include Next.js, Angular, React, among others.</li>
                  <li><b>Design Pattern:</b> Your frontend code must adhere to the MVVM (Model, View, ViewModel) pattern.</li>
                  <li><b>Style Guide:</b> Use the css properties provided by Figma design.</li>
                  <li><b>Code Hosting and Open-Sourcing:</b> Upload your project to a web hosting site and make your code publicly available.</li>
                  <li><b>Submission:</b> Please submit the link to your hosted web application.</li>
               </ul>
               `,
        emphasized: true,
      },
      {
        header: "Prizes",
        body: "The top 3 participants will be awarded prizes and merchandise from Tublian",
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge will last 4 weeks. Get started today!"
      },
      {
        header: "8020",
        body: `Leverage <b>8020</b> to efficiently learn how to <a href="/learn?prompt=${learningPrompt}">develop dashboard applications</a> from Figma designs.`,
      },
    ],
    resources: [],
    additionalResources: [
    ],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
}


const createFrontendChallenge203 = (userId: string, learningPrompt: string) => {
  return {
    title: "Frontend Challenge",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 12,
    firstPrize: "Special Prize",
    secondPrize: "Tublian Merchandize",
    // thirdPrize: "Tublian Merchandize",
    prizeImage: "https://tublian-newsletter-assets.s3.amazonaws.com/tublian-tshirt-merchandize.png",
    duration: "4 week(s)",
    start: "8th March - 9 am EST",
    end: "5th Apr - 6:00 pm EST",
    submissionLinkText: "Share link to your GitHub repository",
    submissionLinkValidator: (link: string) => {
      var regex = /^https:\/\/github\.com\/[a-zA-Z0-9]+\/[a-zA-Z0-9_.-]+\/?$/;
      return !!link.match(regex);
    },
    body: [
      {
        header: "Introduction",
        body: "Welcome, Tublian front-end developers! This challenge is your opportunity to hone your programming skills, showcase your expertise, and earn Streetcred."
      },
      {
        header: "Why Participate?",
        body: "Tublian challenges offer real-world software development experience. In the professional sphere, teams consist of project managers, designers, QA testers, backend developers, and front-end developers like you.<br/><br/>By participating, you'll gain insight into the frontend development workflow used globally, including at Tublian, a skill in high demand for both full-time positions and <a href='https://www.fiverr.com/gigs/figma-to-html'>freelancing</a>."
      },
      {
        header: "Objective: Build a Custom User Dashboard",
        body: `As a member of the TublianX web development team, your task is to develop a new customer onboarding application.
               The team's designer has provided the Figma design, which you can find here: <br><br/>
               <a href='https://www.figma.com/file/mSSwNlEXQoNnxqaLYBQC4d/LYT-Dashboard?type=design&node-id=0-1&mode=design&t=dhc2iP4hRwjS8n4x-0 ' target='_blank'>
               <img src='https://www.figma.com/file/mSSwNlEXQoNnxqaLYBQC4d/thumbnail?ver=thumbnails/4fa8bb6a-6aa9-48ff-85af-46b20793cde3' width='50%'/>
               </a>
               <br/>
               <a href='https://www.figma.com/file/mSSwNlEXQoNnxqaLYBQC4d/LYT-Dashboard?type=design&node-id=0-1&mode=design&t=dhc2iP4hRwjS8n4x-0' target='_blank' style='font-size: 20px'>Click here to view the Figma Design</a>
               <br/><br/>
               Your mission is to implement the front end of this application, transforming the design into a pixel perfect functional interface.`
      },
      {
        header: "Requirements",
        body: `<ul>
                  <li><b>Tech Stack Selection:</b> Choose your preferred tech stack. Options include Next.js, Angular, React, among others.</li>
                  <li><b>Design Pattern:</b> Your frontend code must adhere to the MVVM (Model, View, ViewModel) pattern.</li>
                  <li><b>Style Guide:</b> Use the css properties provided by Figma design.</li>
                  <li><b>Code Hosting and Open-Sourcing:</b> Host your project on GitHub and make it open-source.</li>
                  <li><b>Submission:</b> Once completed, submit your GitHub repository link.</li>
               </ul>
               `,
        emphasized: true,
      },
      {
        header: "Prizes",
        body: "The top 10 participants will be awarded prizes and merchandise from Tublian",
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge will last 4 weeks.. Get started today!"
      },
      {
        header: "8020",
        body: `Leverage <b>8020</b> to efficiently learn how to <a href="/learn?prompt=${learningPrompt}">develop dashboard applications</a> from Figma designs.`,
      },
    ],
    resources: [],
    additionalResources: [
    ],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
}

const createFrontendChallenge = (userId: string) => {
  return {
    title: "Frontend Challenge",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 62,
    firstPrize: "Special Prize",
    secondPrize: "Tublian Merchandize",
    // thirdPrize: "Tublian Merchandize",
    prizeImage: "https://tublian-newsletter-assets.s3.amazonaws.com/tublian-tshirt-merchandize.png",
    duration: "3 week(s)",
    start: "17th Nov - 9 am EST",
    end: "15th Dec - 6:00 pm EST",
    submissionLinkText: "Share link to your GitHub repository",
    submissionLinkValidator: (link: string) => {
      var regex = /^https:\/\/github\.com\/[a-zA-Z0-9]+\/[a-zA-Z0-9_.-]+\/?$/;
      return !!link.match(regex);
    },
    body: [
      {
        header: "Introduction",
        body: "Welcome, Tublian front-end developers! This challenge is your opportunity to hone your programming skills, showcase your expertise, and earn Streetcred."
      },
      {
        header: "Why Participate?",
        body: "Tublian challenges offer real-world software development experience. In the professional sphere, teams consist of project managers, designers, QA testers, backend developers, and front-end developers like you.<br/><br/>By participating, you'll gain insight into the frontend development workflow used globally, including at Tublian, a skill in high demand for both full-time positions and <a href='https://www.fiverr.com/gigs/figma-to-html'>freelancing</a>."
      },
      {
        header: "Objective: Build a Customer Onboarding Application",
        body: `As a member of the TublianX web development team, your task is to develop a new customer onboarding application.
               The team's designer has provided the Figma design, which you can find here: <br><br/>
               <a href='https://www.figma.com/file/nVrVk2IW073N9uwRGUWBj0/challenge?node-id=0%3A1&mode=dev' target='_blank'>
               <img src='https://www.figma.com/file/nVrVk2IW073N9uwRGUWBj0/thumbnail?ver=thumbnails/12d73358-0733-4324-a3b7-d322bb107539' width='50%'/>
               </a>
               <br/>
               <a href='https://www.figma.com/file/nVrVk2IW073N9uwRGUWBj0/challenge?node-id=0%3A1&mode=dev' target='_blank' style='font-size: 20px'>Click here to view the Figma Design</a>
               <br/><br/>
               Your mission is to implement the front end of this application, transforming the design into a pixel perfect functional interface.`
      },
      {
        header: "Requirements",
        body: `<ul>
                  <li><b>Tech Stack Selection:</b> Choose your preferred tech stack. Options include Next.js, Angular, React, among others.</li>
                  <li><b>Design Pattern:</b> Your frontend code must adhere to the MVVM (Model, View, ViewModel) pattern.</li>
                  <li><b>Style Guide:</b> Use the css properties provided by Figma design.</li>
                  <li><b>Code Hosting and Open-Sourcing:</b> Host your project on GitHub and make it open-source.</li>
                  <li><b>Submission:</b> Once completed, submit your GitHub repository link.</li>
               </ul>
               <br/><b>Bonus Task:</b> <br/><br/> Integrate the application with Stripe and develop the necessary backend API.`,
        emphasized: true,
      },
      {
        header: "Prizes",
        body: "The top 10 participants will be awarded prizes and merchandise from Tublian",
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge will last 3 weeks.. Get started today!"
      },
    ],
    resources: [
      {
        avatarUrl: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*7274GG5n2oUxC9kgP4E5NQ.png",
        thumbnailUrl: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*7274GG5n2oUxC9kgP4E5NQ.png",
        title: "Figma tutorial for frontend developers",
        link: "https://medium.com/@designduck/figma-tutorial-for-frontend-developers-9a616677503",
        description:
          "This tutorial intended for developers consuming the design to develop products",
        type: "website"
      },
      {
        avatarUrl: "https://hackernoon.imgix.net/images/XeG8pux45khbf8wrmTFocIiau5g1-pk93y24.gif",
        thumbnailUrl: "https://hackernoon.imgix.net/images/XeG8pux45khbf8wrmTFocIiau5g1-pk93y24.gif",
        title: "UI/UX Design Tips for Developers",
        link: "https://hackernoon.com/ending-front-end-nightmare-uiux-design-tips-for-developers",
        description: "Understanding user interface design principles, user experience, and user-friendly design is a strategic advantage for engineers",
        type: "website"
      },
      {
        avatarUrl: "https://i.ytimg.com/vi/fo6rvTP9kkc/maxresdefault.jpg",
        thumbnailUrl: "https://i.ytimg.com/vi/fo6rvTP9kkc/maxresdefault.jpg",
        title: "What is MVVM (Model-View-ViewModel) Pattern?",
        link: "https://www.youtube.com/watch?v=fo6rvTP9kkc",
        description: "Confused about MVVM? Watch this video.",
        type: "youtube",
      },
      {
        avatarUrl: "https://res.cloudinary.com/practicaldev/image/fetch/s--P7jHFmhF--/c_imagga_scale,f_auto,fl_progressive,h_500,q_auto,w_1000/https://thepracticaldev.s3.amazonaws.com/i/sy4o5xls2mphfqxh3t1z.png",
        thumbnailUrl: "https://res.cloudinary.com/practicaldev/image/fetch/s--P7jHFmhF--/c_imagga_scale,f_auto,fl_progressive,h_500,q_auto,w_1000/https://thepracticaldev.s3.amazonaws.com/i/sy4o5xls2mphfqxh3t1z.png",
        title: "How to create a scalable and maintainable front-end architecture",
        link: "https://dev.to/kevtiq/how-to-create-a-scalable-and-maintainable-front-end-architecture-4f47",
        description: "Modern front-end frameworks and libraries make it easy to create reusable UI components. This is a st...",
        type: "website"
      },
    ],
    additionalResources: [
    ],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
};

const createTechnicalWritingChallenge = (userId: string) => {
  return {
    title: "Technical Writing Challenge",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 47,
    firstPrize: "Special Prize",
    secondPrize: "Tublian Merchandize",
    // thirdPrize: "Tublian Merchandize",
    prizeImage: "https://tublian-newsletter-assets.s3.amazonaws.com/tublian-tshirt-merchandize.png",
    duration: "3 week(s)",
    start: "25th Jan - 9 am EST",
    end: "16th Feb - 6:00 pm EST",
    submissionLinkText: "Share link to your blog post",
    submissionLinkValidator: (link: string) => {
      var regex = /^https:\/\/github\.com\/[a-zA-Z0-9]+\/[a-zA-Z0-9_.-]+\/?$/;
      return !!link.match(regex);
    },
    body: [
      {
        header: "Introduction",
        body: `Welcome to the Tubilan Technical Writing Challenge! This is an exciting opportunity for developers to share their experiences in discovering and contributing to open-source projects using Tubilan and earn some streetcred.
        <br /><br/>
        Are you ready to showcase your technical expertise and elevate your online presence?
        `
      },
      {
        header: "Why Participate?",
        body: `In today's digital era, your online identity often speaks volumes, more so than your physical presence.
        <br />
        Technical writing is not just about showcasing your technical understanding; it's a crucial tool for demonstrating
        clear communication skills – a distinguishing factor in the realm of software development.
        <br/><br/>
        This challenge is your chance to amplify your resume, turning it into a dynamic display of your expertise and
        communication prowess, making you stand out to recruiters. It's more than writing; it's strategically building
        your professional narrative, an investment with substantial career returns.
        `
      },
      {
        header: "Objective: Write a Blog about your Open Source experience",
        body: `The challenge is to share your journey in discovering and contributing to an open-source project via Tubilan.
        Detail your experience in identifying the project, how Tubilan's AI Copilot aided you, tackle the technical challenges
        and community interactions, and highlight any unexpected twists or insights gained.`
      },
      {
        header: "Requirements",
        body: `
              To participate in the Tubilan Technical Writing Challenge, ensure you meet the following requirements:
              <br/><br/>
              <ul>
                <li><b>Access to Tubilan:</b> Register on Tubilan using your GitHub account.</li>
              </ul>
              <ul>
                <li><b>Contribute to Open Source:</b> Write about an open-source project you've previously contributed to, or contribute to a new one and document the experience.</li>
                </ul>
                <br/>
                <h4>Submission Guidelines:</h4>
                <br/>
              <ul>
                <li>Craft a clear and concise technical write-up, not exceeding 1500 words.</li>
                <li>Focus on your discovery process, the assistance from Tubilan's AI Copilot, the technical challenges faced, and your approach to problem-solving.
                </li>
                <li>Include code samples and screenshots as needed to enhance understanding.</li>
                <li>It should cater to both technical and non-technical audiences.</li>
                <li>Publish your article on your favorite blogging platform.</li>
                <li>Submit the link to your published work.</li>
              </ul>
              <br/><br/>
              Please take a look at the resources section for tips on writing a good technical blog.
                `,
        emphasized: true,
      },
      {
        header: "Prizes",
        body: "The top 10 participants will be awarded prizes and merchandise from Tublian",
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge will last 2 weeks.. Get started today!"
      },
    ],
    resources: [
      {
        avatarUrl: "https://www.gstatic.com/devrel-devsite/prod/vb47a36f3a983ed748bf281529457db47955fe57e2b5ea15e7e9641c5e7b5032e/developers/images/opengraph/white.png",
        thumbnailUrl: "https://www.gstatic.com/devrel-devsite/prod/vb47a36f3a983ed748bf281529457db47955fe57e2b5ea15e7e9641c5e7b5032e/developers/images/opengraph/white.png",
        title: "Technical Writing  |  Google for Developers",
        link: "https://developers.google.com/tech-writing",
        description: "Technical Writing Courses for Engineers",
        type: "website",
      },
      {
        avatarUrl: "https://media.licdn.com/dms/image/D5612AQEqvU79tAOThg/article-cover_image-shrink_600_2000/0/1686546082763?e=2147483647&v=beta&t=2v0WKN-RADCKiD7OhgY-OszpEdqnI3pO7q-6aSlyN7Q",
        thumbnailUrl: "https://media.licdn.com/dms/image/D5612AQEqvU79tAOThg/article-cover_image-shrink_600_2000/0/1686546082763?e=2147483647&v=beta&t=2v0WKN-RADCKiD7OhgY-OszpEdqnI3pO7q-6aSlyN7Q",
        title: "The Ultimate Guide to Using GPT for Writing Blogs",
        link: "https://www.linkedin.com/pulse/ultimate-guide-using-gpt-crafting-blogs-any-topic-sanyam-singh/",
        description:
          "If you’re a blogger, content creator, or just someone looking to put together an engaging piece of written content, you’re in the right place. Today, we’re unlocking the power of Generative Pre-trained Transformers (GPT) to create blogs on virtually any topic.",
        type: "website"
      },
      {
        avatarUrl: "https://media.dev.to/cdn-cgi/image/width=1000,height=500,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2Fxtsjf89kaq9s9j35b7qf.png",
        thumbnailUrl: "https://media.dev.to/cdn-cgi/image/width=1000,height=500,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2Fxtsjf89kaq9s9j35b7qf.png",
        title: "The Ultimate Guide to Writing Technical Blog Posts",
        link: "https://dev.to/blackgirlbytes/the-ultimate-guide-to-writing-technical-blog-posts-5464",
        description: "Table of Contents     Really long introduction about how I started writing tech blog...",
        type: "website"
      },
    ],
    additionalResources: [
    ],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
}

const createRagLLMChallenge = (userId: string) => {
  return {
    title: "Build a RAG application using LLM",
    challengeStatus: "Open",
    score: 5,
    participantsCnt: 12,
    firstPrize: "Special Prize",
    secondPrize: "Tublian Merchandize",
    // thirdPrize: "Tublian Merchandize",
    prizeImage: "https://tublian-newsletter-assets.s3.amazonaws.com/tublian-tshirt-merchandize.png",
    duration: "8 week(s)",
    start: "20th Nov - 9 am EST",
    end: "19th Jan - 6:00 pm EST",
    submissionLinkText: "Share link to your GitHub repository",
    submissionLinkValidator: (link: string) => {
      var regex = /^https:\/\/github\.com\/[a-zA-Z0-9]+\/[a-zA-Z0-9_.-]+\/?$/;
      return !!link.match(regex);
    },
    body: [
      {
        header: "Introduction",
        body: "Welcome, Tublian backend developers! This challenge is your opportunity to hone your programming skills, showcase your expertise, and earn Streetcred."
      },
      {
        header: "Why Participate?",
        body: `Learn how to build Retrieval Augmented Generation (RAG) applications using Large Language Models (LLM).
               <br/><br/>
               <b>What is RAG?​</b> <br/><br/>
               RAG is a technique for augmenting LLM knowledge with additional, often private or real-time, data.
               <br/><br/>
               Large Language Models (LLMs) have knowledge up to a certain training date and can reason on various topics.
               To handle private or newer data, they need Retrieval Augmented Generation (RAG) to integrate specific,
               updated information into their prompts.
               <br/><br/>
               So far, RAG applications are the most helpful outcome of the AI revolution.
               <br/><br/>Everyone is using Large Language Models.
               We'll see an even bigger explosion over the next year or so.
               Every <a href='https://www.fiverr.com/search/gigs?query=langchain&source=top-bar&ref_ctx_id=fee9f53729534c9fa74ce34f8e110c82&search_in=everywhere&search-autocomplete-original-term=langchain'>freelancing platform</a> is
               bursting with jobs for people who can build an RAG system from scratch. And this is just starting.
               <br/><br/>This challenge is designed to build this important skill.`
        // Tublian challenges offer real-world software development experience. In the professional sphere, teams consist of project managers, designers, QA testers, backend developers, and front-end developers like you.<br/><br/>By participating, you'll gain insight into the frontend development workflow used globally, including at Tublian, a skill in high demand for both full-time positions and <a href='https://www.fiverr.com/gigs/figma-to-html'>freelancing</a>."
      },
      {
        header: "Objective: Chat with a Book",
        body: ` Why read a book when you can have a conversation with it?
                Your objective is to build a chat interface for the book <a href='https://resources.nvidia.com/en-us-large-language-model-ebooks'>“A Beginner’s Guide to Large Language Models”</a>
                <br/><br/>
                A command line chat interface where users can type in questions and get answers (No UI interface is required). Your
                RAG application will find the answers by searching the book's content.
                <br/><br/>
                <b>Example:</b>
                <br/><br/>
                <b>User:</b> What is a Large Language Model? <br/>
                <b>Chatbot:</b> A large language model is a type of artificial intelligence (AI) system capable of generating
                human-like text based on the patterns and relationships it learns from vast amounts of data.
                <br/><br/>
                <b>User:</b> Why are large language models useful? <br/>
                <b>ChatBot:</b> …`,
      },
      {
        header: "Requirements",
        body: `
                A typical RAG application should have two main components: <br/><br/>
                <b>Indexing:</b> a pipeline for ingesting data from a source and indexing it. This usually happens offline. <br/><br/>
                <b>Retrieval and generation:</b> the actual RAG chain, which takes the user query at run time and retrieves the relevant data from the index, then passes that to the model.
                <br/><br/>
                Similarly, your application will have two components:
                <br/><br/>
                <ul>
                  <li> <b>Load Component:</b> This component will be responsible for loading and storing the book's contents in the database (<a href='https://python.langchain.com/docs/modules/data_connection/vectorstores/#:~:text=A%20vector%20store%20takes%20care,performing%20vector%20search%20for%20you.'>VectorStore</a>).</li>
                </ul>
                <br/>
                <img src='https://python.langchain.com/assets/images/rag_indexing-8160f90a90a33253d0154659cf7d453f.png' width="100%"/>
                <br/>
                <ul>
                  <li><b>Retrieval Component:</b> This component is responsible for generating the answer. Given a user input, it will retrieve relevant information from the database and use LLM to generate an answer.</li>
                </ul>
                <br/>
                <img src='https://python.langchain.com/assets/images/rag_retrieval_generation-1046a4668d6bb08786ef73c56d4f228a.png' width="100%"/>
                <br/>

                <h5>Other key requirements:</h5>
                <br/>
                <ul>
                  <li>Host your code on GitHub and open-source it</li>
                  <li>Submit the link to your GitHub repo once you are done</li>
                </ul>
                <br/>
                <u><h3>We recommend LangChain, Python, and OpenAI for this challenge</h3></u>
                <br/>
                For a quick start - Here is the <a href='https://github.com/Tublian/langchain-rag-template' target='_blank'>template github project</a> configured with all the dependencies.
                `,
        emphasized: true,
      },
      {
        header: "Prizes",
        body: "The top 10 participants will be awarded prizes and merchandise from Tublian",
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge will last 3 weeks.. Get started today!",
      },
    ],
    resources: [
      {
        avatarUrl: "https://i.ytimg.com/vi/T-D1OfcDW1M/maxresdefault.jpg",
        thumbnailUrl: "https://i.ytimg.com/vi/T-D1OfcDW1M/maxresdefault.jpg",
        title: "What is Retrieval-Augmented Generation (RAG)?",
        link: "https://www.youtube.com/watch?v=T-D1OfcDW1M",
        description: "Basics of RAG",
        type: "youtube",
      },
      {
        avatarUrl: "https://python.langchain.com/img/parrot-chainlink-icon.png",
        thumbnailUrl: "https://python.langchain.com/img/parrot-chainlink-icon.png",
        title: "Retrieval-augmented generation (RAG)",
        link: "https://python.langchain.com/docs/use_cases/question_answering/",
        description: "How to guide to build a RAG application using LangChain",
        type: "website"
      },
      {
        avatarUrl: "https://miro.medium.com/v2/resize:fit:1200/1*kSkeaXRvRzbJ9SrFZaMoOg.png",
        thumbnailUrl: "https://miro.medium.com/v2/resize:fit:1200/1*kSkeaXRvRzbJ9SrFZaMoOg.png",
        title: "Retrieval-Augmented Generation (RAG): From Theory to LangChain Implementation",
        link: "https://towardsdatascience.com/retrieval-augmented-generation-rag-from-theory-to-langchain-implementation-4e9bd5f6a4f2",
        description: "From the theory of the original academic paper to its Python implementation with OpenAI, Weaviate, and LangChain",
        type: "website"
      },
      {
        avatarUrl: "https://i.ytimg.com/vi/LhnCsygAvzY/maxresdefault.jpg",
        thumbnailUrl: "https://i.ytimg.com/vi/LhnCsygAvzY/maxresdefault.jpg",
        title: "Chatbots with RAG: LangChain Full Walkthrough",
        link: "https://www.youtube.com/watch?v=LhnCsygAvzY",
        description: "In this video, we work through building a chatbot using Retrieval Augmented Generation (RAG) from start to finish. We use OpenAI's gpt-3.5-turbo Large Langua...",
        type: "youtube"
      },
    ],
    additionalResources: [
    ],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
}


const createTechFestChallenge = (userId: string, learningPrompt: string) => {
  return {
    title: "AI Hack Challenge",
    subTitle: "Tublian X Fusion TechFest 2024",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 12,
    firstPrize: "$100",
    secondPrize: "$70",
    thirdPrize: "$50",
    duration: "1 week",
    start: "20th Aug - 9 am EST",
    end: "28th Aug - 12:00 pm EST",
    needsDetails: true,
    detailsText: "Your team name",
    detailsValidator: (details: string) => {
      return details && details.length > 0;
    },
    submissionLinkText: "Share link to your GitHub repository",
    submissionLinkValidator: (link: string) => {
      var regex = /^https:\/\/github\.com\/[a-zA-Z0-9_.-]+\/[a-zA-Z0-9_.-]+\/?$/;
      return !!link.match(regex);
    },
    body: [
      {
        header: "Introduction",
        body: `Welcome to Tublian, FusionTechFest Hackers! This challenge is your opportunity to hone your programming skills, showcase your expertise, and earn Streetcred through the AI Track that involves both the AWS & Tublian platforms from August 20th, 2024 to August 28th, 2024.
               <br/>
               <img src=${tublianFTFHackChallenge} width='40%' alt='Challenge details' style="margin-left:30%"/>
              `
      },
      {
        header: "Why Participate?",
        body: `The AI Track at FusionTechFest is where you’ll get hands-on experience using cutting-edge tools. Your mission is to push the boundaries of what's possible with AI by utilizing Tublian, AWS Bedrock and Amazon Q Developer.
               <br/><br/>
               The potential for AI innovation is immense, and companies everywhere are hungry for talent that can create meaningful solutions.
               The FusionTechFest AI Track is your opportunity to jump into this exciting field, develop something incredible, and position yourself
               at the forefront of the AI revolution. Get ready to make your mark with the best AI tools in the industry.
              `
      },
      {
        header: "AI Track Challenge: Innovate with Real-Time Data Using AWS Bedrock and Amazon Q Developer",
        body: `
            <b>Challenge Overview:</b> Many African countries face unique challenges in areas like finance, education, agriculture, healthcare,
            urban planning & more, often exacerbated by a lack of localized data and predictive tools. We want you to develop an AI-powered
            solution that addresses a specific local problem, such as optimizing agricultural practices, improving diagnostic accuracy in
            healthcare, enhancing resource management in rapidly growing cities & more. Your solution should leverage AI to build impactful
            solutions that are contextually relevant to African communities, considering data limitations and local nuances.
            <br/><br/>
            <b>Platform Overview:</b> Amazon Bedrock is a fully managed service that offers a choice of high-performing foundation models (FMs)
            from leading AI companies like AI21 Labs, Anthropic, Cohere, Meta, Mistral AI, Stability AI, and Amazon through a single API,
            along with a broad set of capabilities you need to build generative AI applications with security, privacy, and responsible AI
            while Amazon Q Developer is a generative artificial intelligence (AI) powered conversational assistant that can help you
            understand, build, extend, and operate AWS applications.
            <br/><br/>
            <b>Your Objective:</b> Build an AI-powered solution that leverages AWS Bedrock's cutting-edge foundation models alongside Amazon Q Developer's
            advanced capabilities to solve a real-world problem. You’ll need to create a solution that not only uses AI effectively but also
            incorporates real-time or dynamic data to enhance its impact.
            <br/><br/>
            <b>The Task:</b> Develop an interface that uses <a href="https://aws.amazon.com/bedrock/" target="_blank">AWS Bedrock</a> for natural language processing and <a href="https://aws.amazon.com/q/?nc2=h_ql_prod_l1_q" target="_blank">Amazon Q Developer</a> for generating insightful
            responses. Your interface should tackle a specific real-world challenge—whether it’s related to healthcare, finance, education, or any
            other field you’re passionate about.
            <br/><br/>
            For example, you could create a customer service assistant that can pull in the latest product information or support guidelines on-the-fly, or a smart tutor that adapts its teaching methods based on real-time student performance data.
            <br/><br/>
            <b><u>Example Interaction:</u></b>
            <br/><br/>
            <b>User:</b> "What are the latest updates in AWS services?"
            <br/>
            <b>Chatbot:</b> "As of August 2024, AWS has introduced new features including..."
            <br/>
            <b>User:</b> "How can I optimize my machine learning models on AWS?"
            <b>Chatbot:</b> "To optimize your models, you can use AWS SageMaker’s automatic model tuning..."
            <br/><br/>
            <b>Get Creative:</b> You’re encouraged to think outside the box—whether you're addressing urgent societal issues or improving everyday tasks. The more innovative and practical your solution, the better!
            <br/><br/>
            <b>Deadline & Submission:</b> Make sure your project is ready to be submitted by 12pm on the 28th of August. it will be judged based on creativity, technical execution, and the real-world impact of their solution.
            <br/><br/>
            Good luck, and let's create something truly transformative! 🚀
            `
      },
      {
        header: "Requirements",
        body: `
                <ul>
                  <li>You can participate as an individual or a team</li>
                  <li>The application must integrate AWS Bedrock models and Amazon Q Developer.</li>
                  <li>The solution should dynamically update with real-time data, showcasing the power of AI in action.</li>
                  <li>The interface should be mobile or web-based, but it should be intuitive and easy to use.</li>
                  <li>The Lead Team member or Individual must have purchased a ticket to the event day.</li>
                  <li>Each Individual or Lead Team Member is required to submit their Github repository.</li>
                </ul>
                `,
        emphasized: true,
      },
      {
        header: "Prizes",
        body: `The top three participants in the AI Track would be awarded joint cash prizes and swag from AWS and Tublian. 1st position would win a $100 cash prize, $100 in AWS Credits and AWS swag, 2nd position would win a $70 cash prize, $60 in AWS Credits and AWS swag and the 3rd position would win a $500 cash prize, $40 in AWS Credits and AWS swag.`,
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge would last from August 20th to August 28th. Get started today!"
      },
      {
        header: "8020",
        body: `Leverage <b>8020</b>, learn how to <a href="/learn?prompt=${learningPrompt}">build applications using AWS Bedrock models and Amazon Q Developer</a> quickly.`,
      },
    ],
    resources: [],
    additionalResources: [
    ],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
};

const createFrontendChallenge205 = (userId: string) => {
  return {
    title: "8020 Challenge: Learn & Earn! $100",
    subTitle: "Complete a Roadmap & Earn money",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 125,
    firstPrize: "Top 10 participants",
    // secondPrize: "$50",
    // thirdPrize: "$10",
    duration: "2 weeks",
    start: "28th Nov - 9 am EST",
    end: "20th Dec - 12:00 pm EST",
    submissionLinkText: "Share link to your social media post",
    submissionLinkValidator: (link: string) => {
      const urlRegex = /^(https?:\/\/)?([a-z0-9.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i
      return urlRegex.test(link)
    },

    body: [
      {
        header: "Introduction",
        body: `
        We're thrilled to announce a <u>unique</u> challenge to celebrate the new features of Tublian 8020!
        Whether you're eager to enhance your skills or show off your creativity, this challenge has something for everyone.
              `,
      },
      {
        header: "Why Participate?",
        body: `
        Chance to learn new skills, earn a certificate, and win cash prizes while showcasing your skills!.
        <br/>`,
      },
      {
        header: "Challenge Overview",
        body: `

              Here are the steps:
              <ul>
                <li><b>Generate a Learning Roadmap</b>: Head over to Tublian 8020 and create your personalized learning roadmap.</li>
                <li><b>Complete the Roadmap</b>: Dive into the learning materials, answer all the questions, and level up your skills!</li>
                <li><b>Generate a Certificate of Completion</b>: Showcase your hard work by earning and downloading your completion certificate.</li>
                <li><b>Share Your Journey</b>: Post your learning experience and certificate on LinkedIn or Twitter with #LearnWithTublian and tag @Tublian</li>
                <li><b>Submit Your Post</b>: Share the link to your post here to officially complete the challenge.</li>
              </ul>
              <br/><br/>
              <i>💡 Pro tip: Creativity and engagement are key to getting noticed!</i>
            `
      },
      {
        header: "Prizes",
        body: `🎉 Top 10 participants with the most engaging social media posts (based on likes, comments, and shares) will win $10 each!`,
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: `
📅 November 28th - December 13th
<br/>
Don’t wait—start today and make the most of this exciting opportunity!
        `,
      },
    ],
    resources: [],
    additionalResources: [],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
}

const create8020Transcriber = (userId: string, learningPrompt: string) => {
  return {
    title: "Build a 8020 Transcriber",
    subTitle: "Build an API that transcribes youtube video to text",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 29,
    firstPrize: "$50",
    secondPrize: "$35",
    thirdPrize: "$25",
    duration: "2 weeks",
    start: "Jan 12th - 9 am EST",
    end: "Jan 31st - 12:00 pm EST",
    submissionLinkText: "Share link to your GitHub repository",
    submissionLinkValidator: (link: string) => {
      var regex =
        /^https:\/\/github\.com\/[a-zA-Z0-9_.-]+\/[a-zA-Z0-9_.-]+\/?$/;
      return !!link.match(regex);
    },

    body: [
      {
        header: "Introduction",
        body: `Hello Tublian developers! We have a new challenge for you. This is a great chance to learn how to build scalable asynchronous REST APIs and earn some Streetcred. The challenge runs from January 12th, 2025, to January 31st, 2025.
               <br/>
              `,
      },
      {
        header: "Why Participate?",
        body: `
              We need your help.
              <br/><br/>
              We want to improve the chat feature in our 8020 product. Users should be able to ask questions about the content on a page. For example, if someone is watching a YouTube video and has a question about it, the chat should be able to answer.
              <br/><br/>
               The best solution will be integrated directly into our product.`,
      },
      {
        header: "Build Python API that Transcribes YouTube Video to Text",
        body: `
              Your task is to build an API, preferably using Python, that takes a list of YouTube URLs and transcribes them into text.
              You can use open-source solutions like (e.g. https://github.com/jdepoix/youtube-transcript-api) or any other library.
              <br/>
              <br/>
              Because transcription can take a long time, your API should use an asynchronous Request-Reply pattern with a callback URL.
              <br/>
              <br/>
              You need to create two endpoints:
              <br/>
              <br/>
              1. <b>POST /transcribe</b>
                 <ul>
                  <li>Accepts a list of YouTube URLs and a callback URL.</li>
                  <li>Returns a job ID immediately.</li>
                  <li>Later, sends back the transcript to the provided callback URL.</li>
                 </ul>
              <br/>
              2. <b>POST /transcribe/callback/{job_id}</b>
                  <ul>
                    <li>Accepts a YouTube URL and the full transcript of the video for a given job ID.</li>
                  </ul>
              `
      },
      {
        header: "Requirements",
        body: `
                <ul>
                  <li>Implement the Request-Reply pattern using a callback URL to manage the long-running transcription process.</li>
                  <li>Handle all error scenarios gracefully (e.g., some YouTube videos may not have a transcript).</li>
                  <li>Provide a detailed README that explains how to use the APIs.</li>
                  <li>No frontend development is needed.</li>
                </ul>
                `,
        emphasized: true,
      },
      {
        header: "Prizes",
        body: `The top three participants will receive gift cards worth $50, $35, and $25, respectively. Every participant who completes the project earns a digital badge and Streetcred.`,
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge would last from October 12th to Jan 31st. Get started today!",
      },
    ],
    resources: [],
    additionalResources: [],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
};

const createCertificateGenerator = (userId: string, learningPrompt: string) => {
  return {
    title: "Build Certificate Generator",
    subTitle: "Build an End to End Certificate Generator",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 25,
    firstPrize: "$70",
    secondPrize: "$50",
    thirdPrize: "$30",
    duration: "2 weeks",
    start: "15th Oct - 9 am EST",
    end: "31st Oct - 12:00 pm EST",
    submissionLinkText: "Share link to your GitHub repository",
    submissionLinkValidator: (link: string) => {
      var regex =
        /^https:\/\/github\.com\/[a-zA-Z0-9_.-]+\/[a-zA-Z0-9_.-]+\/?$/;
      return !!link.match(regex);
    },

    body: [
      {
        header: "Introduction",
        body: `Welcome, Tublian FullStack developers! This challenge is your opportunity to hone your programming skills, showcase your expertise, and earn Streetcred. The challenge runs from October 15th, 2024, to October 31st, 2024.
               <br/>
              `,
      },
      {
        header: "Why Participate?",
        body: `
              We need your help.
              <br/><br/>
              We want to automate the Tublian certificate generation process. Believe it or not, we currently do it manually!
              <br/><br/>
              We will integrate the winning solution into our product and automate the certification process for 8020.`,
      },
      {
        header: "Objective: 8020 Certificate Generator",
        body: `
               As a member of the TublianX web development team, your task is to build an automated certificate generator for 8020.
               <br/>
               The team's designer has provided the Figma design for the certificate, which you can find here: <br><br/>
               <a href='https://www.figma.com/design/724ZWEVZbRCYlxAUtTtawX/Certificate-Challange?node-id=0-1&t=hUukOEvI9AvuLW1E-1' target='_blank'>
               <img src='https://www.figma.com/file/JC2vrjunQIZz6U1XhWdo4g/thumbnail?ver=thumbnails%2Fb3dde2d0-7e74-4990-941b-7f8f8860e9b1&t=l5CmVlUvp5wu6xiw-0' width='50%'/>
               </a>
               <br/>
               <a href='https://www.figma.com/design/724ZWEVZbRCYlxAUtTtawX/Certificate-Challange?node-id=0-1&t=hUukOEvI9AvuLW1E-1' target='_blank' style='font-size: 20px'>Click here to view the Figma Design</a>
               <br/><br/>
               Your mission is to build an end-to-end solution that will generate certificates on demand.`
      },
      {
        header: "Requirements",
        body: `
                <ul>
                  <li>The name, certificate description, certificate ID, and date will change for each certificate.</li>
                  <li>Display the generated certificate in the browser with an option to download as a PDF.</li>
                  <li>The solution needs to use React.js, Material UI, or pure CSS.</li>
                </ul>
                `,
        emphasized: true,
      },
      {
        header: "Prizes",
        body: `The top three participants will receive gift cards worth $70, $50, and $30, respectively. Every participant who completes the project earns a digital badge and Streetcred.`,
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge would last from October 15th to October 31st. Get started today!",
      },
      {
        header: "8020",
        body: `Leverage <b>8020</b>, learn how to <a href="/learn?prompt=${learningPrompt}">build frontend applications using Reactjs and Material UI</a> quickly.`,
      },
    ],
    resources: [],
    additionalResources: [],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
};
const createDevDocGenie = (userId: string, learningPrompt: string) => {
  return {
    title: "Build an API documentation assistant using LLM",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 12,
    firstPrize: "Special Prize",
    secondPrize: "Tublian Merchandize",
    // thirdPrize: "Tublian Merchandize",
    prizeImage: "https://tublian-newsletter-assets.s3.amazonaws.com/tublian-tshirt-merchandize.png",
    duration: "4 week(s)",
    start: "1st Aug - 9 am EST",
    end: "31st Aug - 6:00 pm EST",
    submissionLinkText: "Share link to your GitHub repository",
    submissionLinkValidator: (link: string) => {
      var regex = /^https:\/\/github\.com\/[a-zA-Z0-9]+\/[a-zA-Z0-9_.-]+\/?$/;
      return !!link.match(regex);
    },
    body: [
      {
        header: "Introduction",
        body: "Welcome, Tublian backend developers! This challenge is your opportunity to hone your programming skills, showcase your expertise, and earn Streetcred."
      },
      {
        header: "Why Participate?",
        body: `Learn how to build Retrieval Augmented Generation (RAG) applications using Large Language Models (LLM).
               <br/><br/>
               <b>What is RAG?​</b> <br/><br/>
               RAG is a technique for augmenting LLM knowledge with additional, often private or real-time, data.
               <br/><br/>
               Large Language Models (LLMs) have knowledge up to a certain training date and can reason on various topics.
               To handle private or newer data, they need Retrieval Augmented Generation (RAG) to integrate specific,
               updated information into their prompts.
               <br/><br/>
               So far, RAG applications are the most helpful outcome of the AI revolution.
               <br/><br/>Everyone is using Large Language Models.
               We'll see an even bigger explosion over the next year or so.
               Every <a href='https://www.fiverr.com/search/gigs?query=langchain&source=top-bar&ref_ctx_id=fee9f53729534c9fa74ce34f8e110c82&search_in=everywhere&search-autocomplete-original-term=langchain'>freelancing platform</a> is
               bursting with jobs for people who can build an RAG system from scratch. And this is just starting.
               <br/><br/>This challenge is designed to build this important skill.`
      },
      {
        header: "DocDevGenie: Always-Updated API Assistance for Developers",
        body: `
            DocDevGenie revolutionizes API documentation access for developers by addressing a critical gap in existing LLM-based AI tools. Traditional tools often rely on outdated versions of API documentation, which can be problematic given the rapid evolution of popular libraries and frameworks.
            <br/><br/>
            DocDevGenie ensures that developers receive the most current and relevant documentation assistance.
            <br/><br/>
            Your objective is to build a chat interface for React API documentation, specifically  <a href='https://react.dev/reference/react'>“Reactjs 18.3.1 documentation”</a>
            <br/><br/>
            You are also free to choose any API documentation you like as long as it is the latest release (less than two months old), ensuring that we know for certain LLMs have no knowledge of it.
            <br/><br/>
            A command line chat interface where users can type in questions and get answers (web UI is optional).
            <br/><br/>
            <b>Example:</b>
            <br/><br/>
            <b>User:</b> What is act? <br/>
            <b>Chatbot:</b> act is a test helper to apply pending React updates before making assertions.
            <br/><br/>
            <b>User:</b> Show me how to use forwardRef <br/>
            <b>ChatBot:</b> …`
      },
      {
        header: "Requirements",
        body: `
                A typical RAG application should have two main components: <br/><br/>
                <b>Indexing:</b> a pipeline for ingesting data from a source and indexing it. This usually happens offline. <br/><br/>
                <b>Retrieval and generation:</b> the actual RAG chain, which takes the user query at run time and retrieves the relevant data from the index, then passes that to the model.
                <br/><br/>
                Similarly, your application will have two components:
                <br/><br/>
                <ul>
                  <li> <b>Load Component:</b> This component will be responsible for loading and storing the book's contents in the database (<a href='https://python.langchain.com/docs/modules/data_connection/vectorstores/#:~:text=A%20vector%20store%20takes%20care,performing%20vector%20search%20for%20you.'>VectorStore</a>).</li>
                </ul>
                <br/>
                <img src='https://python.langchain.com/v0.2/assets/images/rag_indexing-8160f90a90a33253d0154659cf7d453f.png' width="100%"/>
                <br/>
                <ul>
                  <li><b>Retrieval Component:</b> This component is responsible for generating the answer. Given a user input, it will retrieve relevant information from the database and use LLM to generate an answer.</li>
                </ul>
                <br/>
                <img src='https://python.langchain.com/v0.2/assets/images/rag_retrieval_generation-1046a4668d6bb08786ef73c56d4f228a.png' width="100%"/>
                <br/>

                <h5>Other key requirements:</h5>
                <br/>
                <ul>
                  <li>Host your code on GitHub and open-source it</li>
                  <li>Submit the link to your GitHub repo once you are done</li>
                </ul>
                <br/>
                <u><h4>We recommend LangChain, GroqCloud and Llama3 for this challenge</h4></u>
                <br/>
                Groq is a hardware/software platform to build GenAI applications, and it's free for developers.
                Llama3 is an open-source Large Language Model. Learn more about it here.
                <br/><br/>
                <b>Steps to get started:</b>
                <br/>
                <ul>
                    <li>Create an account on <a href="https://console.groq.com/login" target="_blank">GroqCloud</a></li>
                    <li>Generate an API key <a href="https://console.groq.com/keys" target="_blank">here<a/></li>
                    <li><a href="https://console.groq.com/docs/quickstart" target="_blank">Quickstart</a> guide to install necessary dependencies.</li>
                </ul>
                <br/><br/>
                For Python developers, here is a template GitHub project configured with all the dependencies. Feel free use it.
                <a href="https://github.com/Tublian/groq-llama3-template" target="_blank">Python Template Project</a>
                `,
        emphasized: true,
      },
      {
        header: "Prizes",
        body: "The top 10 participants will be awarded prizes and merchandise from Tublian",
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge will last 4 weeks. Get started today!"
      },
      {
        header: "8020",
        body: `Leverage <b>8020</b>, learn how to <a href="/learn?prompt=${learningPrompt}">build RAG applications</a> quickly.`,
      },
    ],
    resources: [],
    additionalResources: [
    ],
    postScriptum: [
      "Join our <a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>'Tublian challenge' Discord channel</a> for collaboration and queries.",
      "Good luck!!!",
    ],
  };
};

const createPromptEngineeringChallenge = (userId: string) => {
  const apiKey = process.env.REACT_APP_PROFILE_BACKEND_API_KEY;
  const baseUrl = process.env.REACT_APP_PROFILE_BACKEND_API_BASE;
  return {
    title: "Prompt Engineering Challenge",
    challengeStatus: "Closed",
    score: 5,
    participantsCnt: 62,
    firstPrize: "$100 gift card",
    secondPrize: "$75 gift card",
    thirdPrize: "$50 gift card",
    duration: "3 week(s)",
    start: "30th Aug - 9 am EST",
    end: "22nd Sept - 6:00 pm EST",
    submissionLinkText: "Paste your ChatGPT link",
    submissionLinkValidator: (link: string) => {
      const regex =
        /^https:\/\/chat\.openai\.com\/(?:share\/)?\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/;
      return !!link.match(regex);
    },
    body: [
      {
        header: "Introduction",
        body: "Welcome to the first Tublian Challenge! This is your chance to sharpen your programming skills, demonstrate your prowess, and earn Streetcred. <br/> <br/>Tublian Challenges span across a variety of developer tasks, including contributing to open-source projects, fixing broken code, learning new programming languages, and more. All are aimed at molding you into a 10x developer."
      },
      {
        header: "Objective: Build a Discord Bot",
        body: "Your mission, should you choose to accept it, is to build a Discord bot named “tubliBot”. The catch is that you must do so without writing a code. You're to use Prompt Engineering & ChatGPT to generate the entire solution."
      },
      {
        header: "Requirements",
        body: `<ul><li>The bot should include a new slash command: "tubliBot"</li><li>This command will take two arguments: "type" (with two options, "opensource" and "lookup") and "query" (an open-ended text input)</li><li>The "opensource" command will allow users to search for open source projects and "lookup" will find profile of Tublian users by their tublian id.</li><li>Example: <b>/tubliBot</b> opensource 'python projects with NumPy'</li><li>Example: <b>/tubliBot</b> lookup ${userId}</li><li>Utilize these two external APIs:<ul><li>Find opensource projects:</li><li>POST - ${baseUrl}/ft/tublibot/github/project/search</li><li>Lookup users:</li><li>GET - ${baseUrl}/ft/tublibot/lookup/user/{userId}</li></ul></li><li>These APIs need an API key, which you must include in a header as "x-api-key": ${apiKey}</li><li>You can use either Python or JavaScript. If using JavaScript, use discord.js v13; if Python, use discord-py-interactions.</li></ul><br/><b>Bonus Task:</b> <br/><br/> Create scripts to deploy your bot on AWS.`,
        emphasized: true,
      },
      {
        header: "Why Learn Prompt Engineering?",
        body: "As we stride into the future of software development, working with Copilots, AI tools, and LLMs will become the norm. Adapting to this new reality means learning new disciplines like Prompt Engineering.<br/><br/>Prompt engineering is a new discipline and a way to interact with some of these tools, and we think it's critical for developers to learn this new skill.<br/><br/>Integrating AI tools into your workflow will increase your productivity and help you stand out as a developer."
      },
      {
        header: "Prizes",
        body: "The top three participants will receive gift cards worth $100, $75, and $50, respectively. Every participant who completes the project earns a digital badge and Streetcred.",
        emphasized: true,
      },
      {
        header: "Challenge Duration",
        body: "The challenge commences now and wraps up in two weeks on 15th Sept. Get started today!"
      },
    ],
    resources: [
      {
        avatarUrl: "https://i.ytimg.com/vi/Q7Hgp6bg0kI/maxresdefault.jpg",
        thumbnailUrl: "https://i.ytimg.com/vi/Q7Hgp6bg0kI/maxresdefault.jpg",
        title: "How to make a Discord Bot using Discord.js v14",
        link: "https://www.youtube.com/watch?v=Q7Hgp6bg0kI&list=PL_cUvD4qzbkwA7WITceoc2_FFjQsBkwX7",
        description:
          "This episode covers how to:1. Add a Discord Bot to your server2. Install Discord.js V143. How to get your Bot TokenJoin my Discord for more help: https://dis...",
        type: "youtube"
      },
      {
        avatarUrl: "https://i.ytimg.com/vi/jh1CtQW4DTo/maxresdefault.jpg",
        thumbnailUrl: "https://i.ytimg.com/vi/jh1CtQW4DTo/maxresdefault.jpg",
        title: "[Python] Slash Commands in Less than 10 Minutes ",
        link: "https://www.youtube.com/watch?v=jh1CtQW4DTo",
        description: "Hey!Thanks for watching today's video, I hope you enjoyed and joined my discorddd... also thanks for 3.63K subscribers!! LINKS - Join my Discord - https://di...",
        type: "youtube"
      },
      {
        avatarUrl: "https://wordpress.deeplearning.ai/wp-content/uploads/2023/04/DLAI-OAI-Image-Preview.png",
        thumbnailUrl: "https://wordpress.deeplearning.ai/wp-content/uploads/2023/04/DLAI-OAI-Image-Preview.png",
        title: "ChatGPT Prompt Engineering for Developers",
        link: "https://www.deeplearning.ai/short-courses/chatgpt-prompt-engineering-for-developers/",
        description: "What you’ll learn in this course In ChatGPT Prompt Engineering for Developers, you will learn how to use a large language model (LLM) to quickly build new and powerful applications.  Using the OpenAI API, you’ll...",
        type: "website"
      },
      {
        avatarUrl: "https://i.ytimg.com/vi/i_a9bqvqmzo/maxresdefault.jpg",
        thumbnailUrl: "https://i.ytimg.com/vi/i_a9bqvqmzo/maxresdefault.jpg",
        title: "How to Build a FULL App With ChatGPT in 20 minutes!",
        link: "https://www.youtube.com/watch?v=i_a9bqvqmzo",
        description: "🤑 Become an in-demand blockchain MASTER: https://dappuniversity.com/bootcamp Subscribe to this channel: https://www.youtube.com/channel/UCY0xL8V6NzzFcwzHCgB8o...",
        type: "youtube",
      },
    ],
    additionalResources: [
      {
        title: "Prompt Engineering Guide",
        link: "https://www.promptingguide.ai/",
        description: "A Comprehensive Overview of Prompt Engineering",
      },
      {
        title: "discord.js Guide",
        link: "https://discordjs.guide/#before-you-begin",
        description: "Imagine a guide... that explores the many possibilities for your discord.js bot.",
      },
      {
        title: "How to Build a Full Stack Application Using ChatGPT",
        link: "https://www.freecodecamp.org/news/build-a-full-stack-application-using-chatgpt/",
        description:
          "ChatGPT can be used to supercharge software development. You even create complete applications with ChatGPT. We just published a course on the freeCodeCamp.org YouTube channel that will show you how you can create a full stack app with ChatGPT. This course, authored by Judy from webdecoded, leverages the power of",
      },
    ],
    postScriptum: [
      "We also encourage you to collaborate with other developers on the platform, as this will help you learn and grow as a developer.",
      "<a href='https://discord.com/channels/771153639238008842/1088150002523705404' target='_blank' rel='noreferrer'>Reach out on Discord</a> if you have questions. Good luck!!!",
    ],
  };
}
