import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
  styled,
} from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import { useTheme } from "@mui/system";
import CustomButton from "../Common/CustomButton";
import { copyToClipboardSvg, downloadSvg } from "../UserHome/Base/SVG";
import { useEffect, useState } from "react";
import { userProfile } from "../../libs/UserProfile";
import FT from "../../libs/FT";
import CopyToClipboard from "react-copy-to-clipboard";

export const Faqs = () => (
  <ul style={{ marginLeft: "20px" }}>
    <li
      style={{
        fontWeight: 700,
        marginBottom: "12px",
        fontSize: "16px",
      }}
    >
      What can I do with the agent?
    </li>
    <Typography
      style={{
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
        marginBottom: "16px",
      }}
    >
      You can register new GitHub repositories using the <b>add</b> command. <br />
      You can also use the <b>help</b> command to get support from the agent on resolving issues.
    </Typography>

    <li
      style={{
        fontWeight: 700,
        marginBottom: "12px",
        fontSize: "16px",
      }}
    >
      How do I run the agent?
    </li>
    <Typography
      style={{
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
        marginBottom: "16px",
      }}
    >
      Use the provided launch script(s) to start the agent. The Tublian Developer Agent is a terminal-based tool that runs locally. After launch, the first step is to add a repository. The agent will then analyze the repository and be ready to accept issues.
    </Typography>

    <li style={{ fontWeight: 700, marginBottom: "12px", fontSize: "16px" }}>
      What kind of issues it can solve?
    </li>
    <Typography
      style={{
        marginBottom: "16px",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
      }}
    >
      The agent works best on small to medium-sized issues—bug fixes, simple features, and refactoring. You can assign tasks by sharing a GitHub issue URL or describing the problem as a prompt.
      <br /><br />
      Think of it as a junior developer: the clearer the issue, the better the result.
    </Typography>
    <li
      style={{
        fontWeight: 700,
        marginBottom: "12px",
        fontSize: "16px",
      }}
    >
      Can Agent commit code to my repository?
    </li>
    <Typography
      style={{
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
        marginBottom: "16px",
      }}
    >
      Yes! The agent can create pull requests and commit code. Just give it write access by adding <a href="https://github.com/tublian-ai-engineer" target="_blank" rel="noreferrer">@tublian-ai-engineer</a> as a collaborator to your repo.
    </Typography>
  </ul>
);

export const Setup = () => {
  const [tosAgentDetails, setTosAgentDetails] = useState<UserTosData | null>(
    null
  );
  const [tosLicenseChecked, setTosLicenseChecked] = useState(false);
  const [copied, setCopied] = useState(false);
  const user = userProfile(localStorage);
  const theme = useTheme();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setTosLicenseChecked(isChecked);
    try {
      const update = await FT.update({
        userId: user.userId(),
        acceptedTosLicense: isChecked,
      });
      console.log("update", update);
      console.log("tosAgentDetails", tosAgentDetails);
    } catch (error) {
      setTosLicenseChecked(!isChecked);
    }
  };

  const copyText = tosAgentDetails
    ? `AGENT_ID=${tosAgentDetails.userId}\nAGENT_KEY=${tosAgentDetails.agentKey}\nOPENAI_API_KEY=${tosAgentDetails.tosApiKey}\nMODEL_SERVICE_NAME=OPENAI`
    : "";

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const downloadAgent = () => {
    window.open(
      "https://tos-downloads.s3.us-east-1.amazonaws.com/0.5/launch_scripts.zip",
      "_blank"
    );
  };
  useEffect(() => {
    const getTosAgentDetails = async () => {
      const response = await FT.get(user.userId(), {});
      if (response) {
        setTosAgentDetails(response);
      }
    };

    getTosAgentDetails();

    // eslint-disable-next-line
  }, [tosLicenseChecked]);

  useEffect(() => {
    if (tosAgentDetails?.acceptedTosLicense !== undefined) {
      setTosLicenseChecked(tosAgentDetails.acceptedTosLicense);
    }
  }, [tosAgentDetails]);

  return (
    <ul>
      <Typography component="p" style={{ marginBottom: "12px" }}>
        <li style={{ marginLeft: "20px" }}>
          <b>Accept the Free License & Subscription Agreement.</b>
        </li>
      </Typography>
      <Typography component="p">
        Please read and agree to the LICENSE AND SUBSCRIPTION AGREEMENT before
        proceeding.
      </Typography>
      <FormGroup sx={{ my: "12px" }}>
        <FormControlLabel
          control={
            <LicenseCheckbox
              onChange={handleChange}
              checked={tosLicenseChecked}
            />
          }
          label={
            <Typography component="p">
              I agree to the{" "}
              <Link
                href="/tos/license"
                target="_blank"
                sx={{ color: "#FDD649", textDecoration: "none" }}
              >
                LICENSE AND SUBSCRIPTION AGREEMENT
              </Link>{" "}
            </Typography>
          }
        />
      </FormGroup>
      <li
        style={{ marginLeft: "20px", marginBottom: "12px", fontSize: "16px" }}
      >
        {" "}
        <b>Configure the Agent.</b>
      </li>
      <Typography component="p" style={{ marginBottom: "5px" }}>
        a) Select a Work Directory <br />
      </Typography>
      <Typography component="p" style={{ marginBottom: "12px" }}>
        Choose a directory on your system for the agent to use. This will be
        where the agent stores all its projects.
      </Typography>
      <Typography component="p" style={{ marginBottom: "5px" }}>
        b) Create the .tos File <br />
      </Typography>
      <Typography component="p" style={{ marginBottom: "12px" }}>
        In the work directory, create a .tos file. Copy the following values and
        save them in the file:
      </Typography>
      {tosLicenseChecked && tosAgentDetails?.acceptedTosLicense && (
        <>
          <Box
            sx={{
              backgroundColor: "#1E1E1E",
              padding: "16px 20px",
              borderRadius: "5px",
              color: "#fefefe",
              display: "flex",
              flexDirection: "column",
              my: "12px",
              gap: "16px",
              width: "611px",
              [theme.breakpoints.down(1200)]: { width: "100%" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                <FormatAlignLeftIcon
                  sx={{ color: "#fefefe", height: "16px" }}
                />
                <Typography component="p">.tos</Typography>
              </Box>
              <CopyToClipboard onCopy={handleCopy} text={copyText}>
                <Box
                  sx={{
                    color: "#fefefe",
                    backgroundColor: "#292929",
                    padding: "4px 10px",
                    borderRadius: "20px",
                    fontSize: "14px",
                    cursor: "pointer",
                    lineHeight: "20px",
                    width: "fit-content",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {copied ? (
                    "Copied!"
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "6px",
                        alignItems: "center",
                      }}
                    >
                      {copyToClipboardSvg}
                      <Typography component="p">Copy</Typography>
                    </Box>
                  )}
                </Box>
              </CopyToClipboard>
            </Box>
            <AgentWindow>
              <Typography component="p">
                AGENT_ID={tosAgentDetails.userId}
              </Typography>
              <Typography component="p">
                AGENT_KEY={tosAgentDetails.agentKey}
              </Typography>
            </AgentWindow>
          </Box>
          <Typography component="p" style={{ marginBottom: "12px" }}>
            <i>These are your special keys to power the agent(DO NOT SHARE).</i>
          </Typography>
        </>
      )}
      <li
        style={{
          marginLeft: "20px",
          marginBottom: "20px",
          fontSize: "16px",
          marginTop: "16px",
        }}
      >
        <b>Run the Agent.</b>
      </li>
      <Typography component="p" style={{ marginBottom: "12px" }}>
        a) Download the launch scripts.
      </Typography>
      <CustomButton
        size="medium"
        disabled={
          !tosLicenseChecked || tosAgentDetails?.acceptedTosLicense === false
        }
        preImg={downloadSvg}
        onClick={downloadAgent}
      >
        Download
      </CustomButton>
      <Typography
        component="p"
        style={{ marginBottom: "12px", marginTop: "12px" }}
      >
        Run the appropriate script based on your operating system:
      </Typography>
      <Typography
        component="p"
        style={{ marginBottom: "12px", marginTop: "12px" }}
      >
        <i>
          For Windows, execute <b>"launch-agent.bat &lt;working dir&gt;"</b>
        </i>
        <br />
        <i>
          For Linux/Mac, execute <b>"launch-agent.sh &lt;working dir&gt;"</b>
        </i>
      </Typography>
    </ul>
  );
};

export const ReleaseNotes = () => {
  return (
    <div>
      <Typography sx={{ mb: "12px" }} component="p">
        The agent now operates in two distinct modes:
      </Typography>
      <ul style={{ marginLeft: "20px" }}>
        <li style={{ lineHeight: "24px", fontSize: "16px" }}>
          <b>Full Mode</b> - The agent manages everything, from identifying the issue to
          creating a pull request.
        </li>
        <li style={{ lineHeight: "24px", fontSize: "16px" }}>
          <b>Code Changes:</b> - The agent switches to this mode when it does not have a write permission to the repository.
        </li>
      </ul>
    </div>
  );
};

export const SupportedProjectTypes = () => {
  return (
    <div>
      <Typography sx={{ mb: "12px" }} component="p">
        This release supports projects using <b>npm</b>, <b>yarn</b>, <b>Maven (mvn)</b>, <b>Gradle</b>, and <b>Python</b>.
        <br />Support for additional project types is in progress and coming soon.
      </Typography>
    </div>
  );
};

export const SystemRequirements = () => {
  return (
    <Box>
      <ul style={{ marginLeft: "20px", marginBottom: "20px" }}>
        <li style={{ lineHeight: "24px", fontSize: "16px" }}>
          Docker version 20.10 or later must be installed and running.
        </li>
        <li style={{ lineHeight: "24px", fontSize: "16px" }}>
          At least 4 GB of free disk space for Docker images and containers.
        </li>
      </ul>
    </Box>
  );
};

export const DocsData = [
  {
    id: "releaseNotes",
    header: "🚀 Release Notes(Version 0.5)",
    content: <ReleaseNotes />,
  },
  {
    id: "supportedProjectTypes",
    header: "🔧 Supported Project Types",
    content: <SupportedProjectTypes />,
  },
  {
    id: "systemRequirements",
    header: "💻 System Requirements",
    content: <SystemRequirements />,
  },
  {
    id: "setupGuide",
    header: "Setup",
    content: <Setup />,
  },
  {
    id: "faqs",
    header: "❓ FAQs",
    content: <Faqs />,
  },
];

const LicenseCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#414141",
  "&.Mui-checked": {
    color: "#FDD649",
  },
  "& .MuiSvgIcon-root": { width: "18px", height: "18px" },
}));

const AgentWindow = styled(Box)(({ theme }) => ({
  "& p": {
    fontFamily: "DejaVu Sans Mono",
    lineHeight: "24px",
    fontSize: "16px",
    overflowWrap: "break-word",
  },
}));

interface UserTosData {
  tosApiKey: string;
  acceptedTosLicense: boolean;
  userId: string;
  agentKey: string;
  modelServiceName: string;
}
