
import { createInstance } from "@amplitude/analytics-browser";
import DateUtils from "./DateUtils";
import queryString from "query-string";
const userAgent = navigator.userAgent;

const amplitude = createInstance();
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY); //dev

const isExistingUser = (createdDate) => {
    if (createdDate) {
        return DateUtils.isOlderThanMonth(createdDate);
    }
    return true;
}

export const setUserGroup = (createdDate) => {
    amplitude.setGroup("userGroup", isExistingUser(createdDate) ? "existing" : "new");
}

const extractUtms = () => {
    const { utm_source, utm_campaign, utm_medium } = queryString.parse(window.location.search);
    const s = {
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        utm_medium: utm_medium
    };
    return s;
}

export const logEventMaybe = (eventType, userId, props = {}) => {
    // console.log("Props", {...props, userAgent});
    if (userId) {
        logEvent(eventType, userId, {...props, userAgent});
    }
}

export const logEvent = (eventType, userId, props = {}) => {
    const utms = extractUtms();
    // console.log("🚀 ~ file: amplitude.js:40 ~ logEvent ~ eventType:", eventType)
    // console.log("🚀 ~ file: amplitude.js:39 ~ logEvent ~ userId:", userId)
    // console.log("🚀 ~ file: amplitude.js:40 ~ logEvent ~ props:", props)
    amplitude.setUserId(userId);
    amplitude.track(eventType, {
        ...props,
        userAgent,
        ...utms
    });
}

export const logGithubIssueEvent = (issue, userId) => {
    const utms = extractUtms();
    logEvent("GithubIssue", userId, {
        repoName: issue.repo?.name,
        title: issue.title,
        labels: issue.labels,
        issueId: issue.issueId,
        repoId: issue.repoId,
        userAgent,
        ...utms
    });
}

export const logGithubProjectEvent = (repoId, repoName, userId) => {
    const utms = extractUtms();
    logEvent("GithubProject", userId, {
        repoName: repoName,
        repoId: repoId,
        userId: userId,
        userAgent,
        ...utms
    });
}
