import { Box, Typography } from "@mui/material";
import sideBanner from "../../../../images/doc-side-banner.png";
import { Link } from "react-router-dom";
import blur from "../../../../images/docs-blur.png";

const TosDocBanner = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgba(30, 30, 30, 1)",
          height: "214px",
          marginRight: "20px",
          borderRadius: "10px",
          border: "1px solid rgba(41, 41, 41, 1)",
          padding: "20px 14px",
          margin: "20px 20px 20px 0",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          color: "#fefefe",
          position: "relative",
        }}
      >
        <img
          src={blur}
          alt="tos blur"
          style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={sideBanner} alt="" />
        </Box>
        <Box>
          <Typography
            style={{ fontSize: "14px", lineHeight: "20px", fontWeight: 700 }}
          >
            Dev AI Agent
          </Typography>
          <Typography
            style={{ fontSize: "12px", lineHeight: "16px", fontWeight: 400 }}
          >
            Access Tublian Developer Agent
          </Typography>
        </Box>
        <Link
          to="/tos-docs"
          style={{
            paddingTop: "6px",
            paddingBottom: "6px",
            backgroundColor: "#292929",
            borderRadius: "25px",
            color: "#fefefe",
            display: "flex",
            justifyContent: "center",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
            textDecoration: "none",
          }}
        >
          Get Started
        </Link>
      </Box>
    </>
  );
};

export default TosDocBanner;
