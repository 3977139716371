import { useState } from "react";
import { Box, Icon, TextareaAutosize, Typography, styled } from "@mui/material";
import {
  CheckCircleOutlined,
  Close,
  Pause,
  PlayArrowRounded,
  StopRounded,
} from "@mui/icons-material";
import CustomButton from "../../../../../Common/CustomButton";
import { microphoneSvg, sendSvg } from "../../../../../UserHome/Base/SVG";
import Spinner from "../../../../../Common/Spinner";
import ResultAnalysis from "./ResultAnalysis";

const QnABtns = ({
  loading,
  result,
  correctness,
  recordingStatus,
  updateRecordingStatus,
  audioState,
  fetchData,
  handleTextAnswer,
  question,
  handleReset,
  maxTime,
  showAnswer,
  questionNumber,
  answered,
}) => {
  const mode = recordingStatus.mode;
  const status = recordingStatus.status;

  const isIdle = status === "idle";
  const isActive = status === "active";
  const isRecording = status === "recording";
  const isPaused = status === "paused";
  const isStopped = status === "stopped";
  const isReplaying = status === "replaying";
  const isUploading = recordingStatus.uploading;
  const isSubmitted = status === "submitted";

  const [questionResponse, setQuestionResponse] = useState("");

  const handleInputChange = (event) => {
    const response = event.target.value;
    setQuestionResponse(response);
  };

  const CloseButton = () => (
    <Box>
      <Close
        htmlColor="#FEFEFE"
        sx={{ cursor: "pointer", width: "17px", height: "17px" }}
        onClick={() => {
          handleButtonClick("active", "_2gd2_");
          handleReset();
        }}
      />
    </Box>
  );

  const handleClickSend = () => {
    if (questionResponse.length > 2) {
      handleTextAnswer(questionResponse, question);
    }
  };

  const handleKeyDown = (event) => {
    // if (event.key === "Enter") {
    //   handleClickSend();
    // }
  };

  const handleButtonClick = (newStatus, selector) => {
    updateRecordingStatus("status", newStatus);
    document.querySelector(`._${questionNumber} .${selector}`).click();
  };

  const disableCopyPaste = (e) => {
    e.preventDefault();
  };

  if (isIdle) {
    if (answered) {
      return (
        <AnsweredBtn sx={{ marginTop: "16px" }}>
          <CheckCircleOutlined /> Answered
        </AnsweredBtn>
      );
    }

    return (
      <Box
        sx={{
          "& button": {
            fontSize: "0.875rem !important",
            lineHeight: "1.25rem !important",
          },
        }}
      >
        <CustomButton
          // preImg={microphoneSvg}
          type="grey"
          size="medium"
          children={"Answer Question"}
          onClick={() => {
            updateRecordingStatus("status", "active"); // For text first
            // updateRecordingStatus("mode", "voice"); // To make voice default
            // handleButtonClick("recording", "_1dpop");
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <QnABtnsWrap>
        {/* Generate Answer */}
        {!loading && showAnswer && (
          <CustomButton
            style={{ width: "170px" }}
            children={"Generate Answer"}
            type="primary"
            size="medium"
            disabled={!isActive && !isSubmitted}
            onClick={() => {
              handleReset();
              updateRecordingStatus("status", "active");
              fetchData(question);
            }}
          />
        )}

        {isActive && mode === "text" && !isUploading && (
          <TextAreaBox onContextMenu={(event) => event.preventDefault()}>
            <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
              <StyledTextArea
                variant="standard"
                placeholder="Type your answer here"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck={false}
                // disabled={disabled}
                value={questionResponse}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onCut={disableCopyPaste}
                onCopy={disableCopyPaste}
                onPaste={disableCopyPaste}
              />

              {!isIdle && (
                <Box>
                  <CloseButton />
                </Box>
              )}
            </Box>

            <InputIconsWrap>
              <Icon
                onClick={() => {
                  updateRecordingStatus("mode", "voice");
                  handleButtonClick("recording", "_1dpop");
                }}
                sx={{ color: "#FEFEFE" }}
              >
                {microphoneSvg}
              </Icon>
              <Icon
                onClick={handleClickSend}
                sx={{
                  color: questionResponse.length > 2 ? "#FEFEFE" : "#888888",
                  cursor: "pointer",
                }}
              >
                {sendSvg}
              </Icon>
            </InputIconsWrap>
          </TextAreaBox>
        )}

        {result.analysis ? (
          <ResultAnalysis
            result={result}
            correctness={correctness}
            isSubmitted={isSubmitted}
            isUploading={isUploading}
            mode={mode}
            audioState={audioState}
            handleReset={handleReset}
            updateRecordingStatus={updateRecordingStatus}
            handleButtonClick={handleButtonClick}
            setQuestionResponse={setQuestionResponse}
          />
        ) : recordingStatus.uploading ? (
          <UploaderWrap>
            <Typography>Please wait while your answer is processed</Typography>
            <span className="spinner">
              <Spinner size={18} pryColor="#FEFEFE" secColor="#121212" />
            </span>
          </UploaderWrap>
        ) : null}

        {!loading && !isIdle && !isSubmitted && mode === "voice" && (
          <RecControlsWrap>
            <Box>
              <div>
                {/* Mic btn */}
                <span style={{ color: "#888", cursor: "pointer" }}>
                  {microphoneSvg}
                </span>

                {/* Duration */}
                <span className="currentDuration">
                  {isStopped || isReplaying // Show duration of audio note
                    ? `
                  ${String(audioState.audioDetails.duration.m).padStart(2, "0")}
                  :
                  ${String(audioState.audioDetails.duration.s).padStart(2, "0")}
                `
                    : ` 
                ${String(
                  //Show countdown in the format MM:SS
                  Math.floor((maxTime - recordingStatus.seconds) / 60)
                ).padStart(2, "0")}
                :
                ${String((maxTime - recordingStatus.seconds) % 60).padStart(
                  2,
                  "0"
                )}
                `}
                </span>
              </div>

              {/* Control Buttons */}
              <div>
                {/* if recording show pause and stop */}
                {isRecording && (
                  <>
                    <Pause
                      htmlColor="#FEFEFE"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleButtonClick("paused", "_3nQu5")}
                    />
                    <StopRounded
                      htmlColor="#F26663"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleButtonClick("stopped", "_1bSom")}
                    />
                  </>
                )}

                {/* If paused, show resume and stop */}
                {isPaused && (
                  <>
                    <PlayArrowRounded
                      htmlColor="#FEFEFE"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleButtonClick("recording", "_3nQu5")}
                    />
                    <StopRounded
                      htmlColor="#F26663"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleButtonClick("stopped", "_1bSom")}
                    />
                  </>
                )}

                {/* If stopped, show play button to listen to vn */}
                {isStopped && (
                  <PlayArrowRounded
                    htmlColor="#FEFEFE"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      updateRecordingStatus("status", "replaying");
                      document.querySelector("div._1YOWG audio").play();
                    }}
                  />
                )}

                {/* If replaying, show pause button to pause listening to vn */}
                {isReplaying && (
                  <Pause
                    htmlColor="#FEFEFE"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      updateRecordingStatus("status", "stopped");
                      document.querySelector("div._1YOWG audio").pause();
                    }}
                  />
                )}
              </div>

              {!loading && (isStopped || isReplaying) && !isUploading && (
                <SecondQnABtnsWrap>
                  <CustomButton
                    children="Submit Answer"
                    type="grey"
                    size="medium"
                    style={{
                      background: "#414141",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: 500,
                    }}
                    onClick={() => handleButtonClick("submitted", "_37kfa")}
                  />
                </SecondQnABtnsWrap>
              )}
            </Box>

            {!isIdle && (
              <Box>
                <CloseButton />
              </Box>
            )}
          </RecControlsWrap>
        )}
      </QnABtnsWrap>
    </>
  );
};

export default QnABtns;

const AnsweredBtn = styled(Typography)({
  borderRadius: "20px",
  background: "#292929",
  padding: "8px 12px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  color: "#FEFEFE",
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "1.25rem",
  width: "119px",
  cursor: "not-allowed",
  "& svg": {
    width: "20px",
    height: "20px",
    color: "#1AFF8C",
  },
});

const QnABtnsWrap = styled(Box)(({ theme }) => ({
  "& div button": {
    padding: "8px 12px !important",
  },
  display: "flex",
  gap: 20,
  marginTop: 16,
  [theme.breakpoints.down(976)]: {
    "& button": {
      fontSize: "0.75rem !important",
    },
  },
  [theme.breakpoints.down(700)]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const TextAreaBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  // alignItems: "center",
  background: "#292929",
  borderRadius: "10px",
  minHeight: "91px",
  padding: "16px 20px 16px 20px",
  flexDirection: "column",
  [theme.breakpoints.down(700)]: {
    width: "100%",
  },
}));

const RecControlsWrap = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: 10,
  paddingLeft: 12,
  gap: "8px",
  background: "#292929",
  padding: "16px 20px 16px 20px",
  //
  "& div": {
    display: "flex",
    alignItems: "center",
    gap: 8,
    "& .currentDuration": {
      color: "#888",
    },
  },
});

const SecondQnABtnsWrap = styled(Box)({
  marginLeft: "8px",
  "& div button": {
    padding: "8px 12px !important",
  },
});

const StyledTextArea = styled(TextareaAutosize)({
  width: "calc(100% - 27px)",
  background: "transparent",
  minHeight: "20px",
  maxHeight: "60px",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  color: "#CFCFCF",
  ":focus": {
    // padding: "8px 12px",
  },
  "::placeholder": {
    color: "#888",
  },
  ":focus::placeholder": {
    // Hide placeholder once active
    color: "transparent",
  },
  resize: "none",
});

const InputIconsWrap = styled(Box)({
  display: "flex",
  gap: "14px",
  alignItems: "flex-end",
  justifyContent: "end",
  // height: "100%",
  paddingBottom: "8px",
  "& svg": {
    width: "18px",
    height: "18px",
  },
});

const UploaderWrap = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 6,
  paddingLeft: "12px",
  background: "#292929",
  borderRadius: "10px",
  padding: "16px 20px 16px 20px",
  width: "100%",
  "& p": {
    fontWeight: 500,
    fontSize: "0.75rem",
    lineHeight: "1rem",
    color: "#888",
  },
  "& span.spinner": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});


