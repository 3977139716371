import { FC, useEffect, useRef } from "react";
import ChatBotView from "./ChatBotView";
import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import useTubliChatBotStyles, { chatBotViewStyles } from "./tubliChatBotStyles";
import { ChatSvg } from "../../UserHome/Base/SVG";
import {
  fetchTubliChatBotChatHistory,
  minChatBoxWidth,
} from "./tubliChatBotResources";
import { useChatBotContext } from "../../Context/ChatBotContext";
import { logEvent } from "../../../libs/amplitude";
import { userProfile } from "../../../libs/UserProfile";

interface TubliChatBotProps {
  view: string;
  queryContext?: string;
}

const TubliChatBot: FC<TubliChatBotProps> = ({ view, queryContext }) => {
  const classes = useTubliChatBotStyles();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { chatBotData, setChatBotData } = useChatBotContext();

  const theme = useTheme();
  const userCache = userProfile(localStorage);

  const toggleChat = () => {
    setChatBotData((prevData) => ({
      ...prevData,
      chatOpen: !chatBotData?.chatOpen,
    }));
  };

  const handleClickChatBot = () => {
    // console.log(chatBotData);
    logEvent(view, userCache.userId(), {
      action: "Opening tublibot",
    });
    if (chatBotData?.chatBotView === "idle popup") {
      setChatBotData((prevData) => ({
        ...prevData,
        chatOpen: true,
        chatBotView: "chat view",
      }));
    } else {
      toggleChat();
    }
  };

  useEffect(() => {
    if (!(chatBotData?.chatBotHistory?.length > 0)) {
      fetchTubliChatBotChatHistory(setChatBotData);
    }
    // eslint-disable-next-line
  }, [chatBotData.messagesList]);
  // }, [chatBotData.currentConversationId, chatBotData.messagesList]);

  useEffect(() => {
    if (chatBotData?.chatBotView === "idle popup" && !chatBotData?.chatOpen) {
      timeoutRef.current = setTimeout(() => {
        setChatBotData((prevData) => ({ ...prevData, chatOpen: true }));
      }, 5000); // Popup after 5 secs
    }

    // Clean up the timeout if component is unmounted before 5 secs
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line
  }, [chatBotData.chatBotView]);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "-10px",
        right: "18px",
        zIndex: 100,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        overflow: "hidden",
        "& .chat": {
          "&__closed": {
            border: "2px solid #FEFEFE",
            background: "linear-gradient(109deg, #FBDA61 0%, #FF5ACD 86.08%)",
          },
          "&__open": {
            border: "2px solid transparent",
            background:
              "linear-gradient(#121212, #121212) padding-box, linear-gradient(109.15deg, #FBDA61 0%, rgba(255, 90, 205, 0.84) 86.08%) border-box",
          },
        },
        width: chatBotData.chatOpen
          ? chatBotData.chatBotView === "idle popup"
            ? "256px"
            : chatBotData?.chatBoxDimensions?.width || minChatBoxWidth
          : "80px",
        height: chatBotData.chatOpen
          ? chatBotData.chatBotView === "idle popup"
            ? "220px"
            : "100vh"
          : "auto",
        gap: chatBotData.chatOpen ? "6px" : "0px",
        [theme.breakpoints.down(769)]: {
          bottom: "0px",
          right: "0px",
          width: chatBotData.chatOpen
            ? chatBotData.chatBotView === "idle popup"
              ? "256px"
              : "100%"
            : "58px",
          height: chatBotData.chatOpen
            ? chatBotData.chatBotView === "idle popup"
              ? "168px"
              : "calc(100vh -  49px)"
            : "auto",
        },
      }}
    >
      <Box style={chatBotViewStyles(chatBotData)}>
        {chatBotData?.chatOpen && (
          <ChatBotView
            onClose={toggleChat}
            view={view}
            queryContext={queryContext}
          />
        )}
      </Box>

      <Box
        className={`${classes.chatBtn} ${
          chatBotData?.chatOpen ? "chat__open" : "chat__closed"
        }`}
        onClick={handleClickChatBot}
      >
        {ChatSvg}
        {ChatSvg}
      </Box>
    </Box>
  );
};

export default TubliChatBot;
