import { FC, createContext, useContext, useState } from "react";
import {
  AgentPaymentDetails,
  CustomerContextType,
  PlanDetail,
  PopupDataType,
  PricingFeaturesCardProps,
  Product,
} from "../TOSCustomerPricingPlan/TOSResources";
import FT from "../../../../libs/FT";
import { logEvent } from "../../../../libs/amplitude";
import { Box } from "@mui/material";
import { BulletSvg } from "../../../UserHome/Base/SVG";

const CustomerContext = createContext<CustomerContextType | undefined>(
  undefined
);

export function useCustomerContext() {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error(
      "useCustomerContext must be used within a CustomerProvider"
    );
  }
  return context;
}

// default payment state
export const defaultAgentPaymentDetails = {
  isActive: false,
  selectedPlan: "",
  name: "",
  email: "",
  companyName: "",
  selectedPrice: "0",
  currency: "$",
  currencyId: "usd",
  selectedPriceId: "",
  selectedInterval: "",
};

export const CustomerProvider: FC = ({ children }) => {
  const [activeButton, setActiveButton] = useState("Monthly");
  const [paymentDetails, setPaymentDetails] = useState<AgentPaymentDetails>(
    defaultAgentPaymentDetails
  );
  const [product, setProduct] = useState<Product>();
  const [loadingPackages, setLoadingPackages] = useState<
    "not_loading" | "loading" | "loaded"
  >("loaded");
  // state that manage payment error and success status and messages
  const [paymentStatusPopup, setPaymentStatusPopup] = useState<PopupDataType>({
    isActive: false,
    msg: "",
    status: null,
    mode: null,
  });
  const [error, setError] = useState({ error: false, errorMsg: "" });
  const [loading, setLoading] = useState(false);
  const [isCheckoutActive, setIsCheckoutActive] = useState(false);
  const [readyToJoinWaitlist, setReadyToJoinWaitlist] = useState(false);

  const letsChat = () => {
    logEvent("Customer_LandingPage", "lets-chat");
    window.open("https://zcal.co/tublian/tos", "_blank");
    return false;
  };

  const handleToggle = (plan: string) => {
    setActiveButton(plan);
  };

  // const monthlyData =
  //   product?.prices &&
  //   product.prices.find(
  //     (stripeProduct) => stripeProduct.recurring.interval === "month"
  //   );

  // const annualData =
  //   product?.prices &&
  //   product.prices.find(
  //     (stripeProduct) => stripeProduct.recurring.interval === "year"
  //   );

  // creating plan array of object based on the response gotten from stripe

  const updatedPlanDetails: PlanDetail[] = [
    {
      plan: "Trial",
      price: "Trial",
      duration: "2 weeks trial",
      cta: "Join waitlist",
      features: <TrialPlanFeatures />,
      id: "1",
      currency: "$",
      currencyId: "usd",
    },
    {
      plan: "Enterprise",
      price: activeButton === "Monthly" ? "Custom" : "Custom",
      duration: "Paid per month",
      cta: "Request Demo",
      features: (
        <EnterprisePlanFeatures isAnnual={activeButton === "Annually"} />
      ),
      id: "3",
      currency: "$",
      currencyId: "usd",
    },
  ];

  const handleCloseModal = () => {
    setPaymentDetails(defaultAgentPaymentDetails);
    setReadyToJoinWaitlist(false);
  };

  // form validations

  const emailPattern = new RegExp(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  );

  const isEmail = () => emailPattern.test(paymentDetails.email);

  const isNameValid = () =>
    !!paymentDetails.name && paymentDetails.name.trim().length > 1;

  const isCompanyNameValid = () =>
    !!paymentDetails.companyName &&
    paymentDetails.companyName.trim().length > 1;

  const isValid = isEmail() && isNameValid() && isCompanyNameValid();

  const handleOpenTOSPricingForm = (
    plan: string,
    price: string,
    priceId?: string,
    interval?: string
  ) => {
    setPaymentDetails((prevData) => ({
      ...prevData,
      isActive: plan !== "Enterprise",
      selectedPlan: plan,
      selectedPrice: price,
      selectedPriceId: priceId,
      selectedInterval: interval,
    }));

    if (plan === "Enterprise") {
      letsChat();
    }
  };

  const handleJoinWaitlist = async (
    name: string,
    email: string,
    companyName: string
  ) => {
    setLoading(true);

    try {
      const waitlistResponse = await FT.addToWaitlist(name, email, companyName);
      if (waitlistResponse.error) {
        throw new Error(waitlistResponse.error);
      }
      setLoading(false);
      setPaymentStatusPopup({
        isActive: true,
        msg: "Successfully joined waitlist",
        status: "success",
        mode: "waitlist",
      });
    } catch (error) {
      setLoading(false);
      setPaymentStatusPopup({
        isActive: true,
        msg: "Error",
        status: "error",
        mode: "waitlist",
      });
    } finally {
      setLoading(false);
      setPaymentDetails((prevData) => ({
        ...prevData,
        name: "",
        email: "",
        companyName: "",
        isActive: false,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValid) {
      return;
    }
    setReadyToJoinWaitlist(true);

    const { name, email, companyName } = paymentDetails;

    if (paymentDetails.selectedPlan === "Pro") {
      setReadyToJoinWaitlist(false);
      setIsCheckoutActive(true);
    } else if (paymentDetails.selectedPlan === "Trial") {
      handleJoinWaitlist(name, email, companyName);
    }
  };

  const contextValue: CustomerContextType = {
    activeButton,
    paymentDetails,
    product,
    loadingPackages,
    paymentStatusPopup,
    error,
    loading,
    setLoading,
    setError,
    setPaymentStatusPopup,
    setLoadingPackages,
    setProduct,
    setPaymentDetails,
    setActiveButton,
    handleCloseModal,
    updatedPlanDetails,
    handleToggle,
    handleOpenTOSPricingForm,
    letsChat,
    isEmail,
    isCompanyNameValid,
    isNameValid,
    isCheckoutActive,
    setIsCheckoutActive,
    readyToJoinWaitlist,
    handleSubmit,
    setReadyToJoinWaitlist,
  };

  // useEffect(() => {
  //   (async () => {
  //     await fetchProduct();
  //   })();
  //   // eslint-disable-next-line
  // }, []);

  return (
    <CustomerContext.Provider value={contextValue}>
      {children}
    </CustomerContext.Provider>
  );
};

// const ProPlanFeatures: React.FC<{ isAnnual?: boolean }> = ({ isAnnual }) => (
//   <Box style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
//     <PricingFeaturesCard
//       text={`${isAnnual ? "24" : "2"} Agents`}
//       gradientId="gradientPro"
//       gradientColors={["rgba(251, 218, 97, 1)", "rgba(255, 90, 205, 0.84)"]}
//     />
//     <PricingFeaturesCard
//       text={`Unlimited issues per ${isAnnual ? "year" : "month"}`}
//       gradientId="gradientPro"
//       gradientColors={["rgba(251, 218, 97, 1)", "rgba(255, 90, 205, 0.84)"]}
//     />
//   </Box>
// );

const EnterprisePlanFeatures: React.FC<{ isAnnual?: boolean }> = ({
  isAnnual,
}) => (
  <Box style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
    <PricingFeaturesCard
      text={`Developer Agent(s)`}
      gradientId="gradientEnterprise"
      gradientColors={["rgba(155, 103, 248, 1), rgba(0, 124, 235, 1)"]}
    />
    <PricingFeaturesCard
      text={`Unlimited issues per ${isAnnual ? "year" : "month"}`}
      gradientId="gradientEnterprise"
      gradientColors={["rgba(155, 103, 248, 1), rgba(0, 124, 235, 1)"]}
    />
    <PricingFeaturesCard
      text="Access to Support/Developers"
      gradientId="gradientEnterprise"
      gradientColors={["rgba(155, 103, 248, 1), rgba(0, 124, 235, 1)"]}
    />
    <PricingFeaturesCard
      text="Self-hosted solution (VPC)"
      gradientId="gradientEnterprise"
      gradientColors={["rgba(155, 103, 248, 1), rgba(0, 124, 235, 1)"]}
    />
    <PricingFeaturesCard
      text="Dedicated Support & Onboarding"
      gradientId="gradientEnterprise"
      gradientColors={["rgba(155, 103, 248, 1), rgba(0, 124, 235, 1)"]}
    />
  </Box>
);

const PricingFeaturesCard: React.FC<PricingFeaturesCardProps> = ({
  text,
  fill,
  gradientId,
  gradientColors,
}) => (
  <Box
    style={{
      display: "flex",
      gap: "2px",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "20px",
      alignItems: "center",
    }}
  >
    <BulletSvg
      fill={fill}
      gradientId={gradientId}
      gradientColors={gradientColors}
    />
    <Box>{text}</Box>
  </Box>
);

const TrialPlanFeatures = () => (
  <Box style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
    <PricingFeaturesCard text="Developer Agent" fill="#121212" />
    <PricingFeaturesCard text="BizDev Agent" fill="#121212" />
    <PricingFeaturesCard text="5 issues per month" fill="#121212" />
    <PricingFeaturesCard text="2 proposal per week" fill="#121212" />
  </Box>
);
