import { Box, styled, Typography } from "@mui/material";
import {
  CheckCircleOutlined,
  HighlightOffRounded,
  Close,
} from "@mui/icons-material";
import CustomButton from "../../../../../Common/CustomButton";
import { linearRefreshSvg } from "../../../../../UserHome/Base/SVG";
import Land from "../../../css/landing.module.css";

const ResultAnalysis = ({
  result,
  correctness,
  isSubmitted,
  isUploading,
  mode,
  audioState,
  handleReset,
  updateRecordingStatus,
  handleButtonClick,
  setQuestionResponse,
}) => {
  return (
    <ResultsWrap>
      <TitleBox>
        <Typography variant="body1">Your Answer</Typography>
      </TitleBox>
      <Box className="content">
        <Typography style={{ color: "#B7B7B7" }}>
          {result.given_answer}
        </Typography>

        <Box
          sx={{
            background: "#292929",
            borderRadius: "10px",
            padding: "16px 20px",
            marginTop: "20px",
            display: "flex",
            gap: "10px",
          }}
        >
          <Box>
            <Typography
              component="h4"
              style={{ margin: "0px 0 8px", fontWeight: 700 }}
              className={Land.topic_name}
              sx={{ display: "flex", alignItems: "center" }}
            >
              Your answer is{" "}
              {result.analysis?.correctness ? "correct" : "not correct"}
              {result.analysis?.correctness ? (
                <CheckCircleOutlined
                  sx={{ marginLeft: "10px" }}
                  htmlColor="#1AFF8C"
                />
              ) : (
                <HighlightOffRounded
                  sx={{ marginLeft: "10px" }}
                  htmlColor="#F26663"
                />
              )}
            </Typography>
            <Typography>{result.analysis?.explanation}</Typography>
            {isSubmitted && !isUploading && (
              <CustomButton
                type="grey"
                size="medium"
                preImg={
                  !correctness ? (
                    linearRefreshSvg
                  ) : (
                    <CheckCircleOutlined htmlColor="#1AFF8C" />
                  )
                }
                style={{
                  background: "#414141",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: 500,
                  marginTop: 12,
                }}
                children={correctness ? "Answered" : "Try Again"}
                onClick={() => {
                  if (!correctness) {
                    if (mode === "voice") {
                      if (audioState.audioDetails.blob) {
                        handleReset();
                      }
                      updateRecordingStatus("mode", "voice");
                      handleButtonClick("recording", "_1dpop");
                    }

                    if (mode === "text") {
                      handleReset();
                      setQuestionResponse("");
                      updateRecordingStatus("status", "active");
                    }
                  }
                }}
              />
            )}
          </Box>
          <Box>
            <Close
              htmlColor="#FEFEFE"
              sx={{ cursor: "pointer", width: "17px", height: "17px" }}
              onClick={() => {
                handleButtonClick("active", "_2gd2_");
                handleReset();
              }}
            />
          </Box>
        </Box>
      </Box>
    </ResultsWrap>
  );
};

export default ResultAnalysis;

const TitleBox = styled(Box)({
  display: "flex",
  gap: 7,
  "& p": {
    color: "#FEFEFE",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: 1.5,
  },
});

const ResultsWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 12,
  marginTop: 20,
  maxHeight: 266,
  "& h4": {
    fontWeight: 700,
    fontSize: "0.875rem",
    lineHeight: 1.25,
  },
  "& p": {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.25,
    color: "#FEFEFE",
  },
  "& span.spinner": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .content": {
    maxHeight: 190,
    overflowY: "scroll",
    paddingRight: 20,
    scrollbarWidth: "thin",
    scrollbarColor: "#888 transparent",
    scrollbarHeight: 64,
    "-ms-scrollbar-height": 64,
    "&::-webkit-scrollbar": {
      width: 4,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      backgroundColor: "#888",
      height: 64,
      minHeight: 64,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  [theme.breakpoints.down(700)]: {
    maxHeight: 506,
    "& .content": {
      maxHeight: 430,
    },
  },
}));
