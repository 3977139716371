import { FC, useEffect, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { useLearnContext } from "./LearnContext";
import { ILearningProgress, RoadmapListItem } from "../Common/LearningTypes";
import CoursePresentationCard from "./CoursePresentationCard";
import ProfileEndpoint from "../../../libs/ProfileEndpoint";
import CustomLoader from "../../Common/CustomLoader";

const RenderDeepDiveSection: FC<RenderDeepDiveSectionProps> = ({
  deepdivePhase,
  roadmapId,
  showDeepdiveRoadmap,
}) => {
  const {
    activeSectionId,
    activeCourse,
    learningProgress,
    loadingState,
    getPhaseProgress,
  } = useLearnContext();

  const [phaseProgress, setPhaseProgress] = useState<
    ILearningProgress | undefined
  >(undefined);

  const showGoDeeperBtn =
    !loadingState.creatingDeepdiveRoadmap &&
    !loadingState.fetchingLearningContent &&
    !loadingState.fetchingMoreLearningContent &&
    learningProgress?.progress?.[activeSectionId] &&
    !activeCourse?.sections.get(activeSectionId)?.goDeeperRoadmapId &&
    activeCourse.phaseType !== "godeeper";

  useEffect(() => {
    const fetchPhaseProgress = async () => {
      try {
        const phaseId = deepdivePhase?.roadmap?.phases?.[0]?.id;
        // console.log("🚀 ~ fetchPhaseProgress ~ phaseId:", phaseId);

        if (phaseId) {
          const response = await ProfileEndpoint.getPhaseStatus(phaseId);

          // console.log("🚀 ~ fetchAllPhaseProgress ~ responses:", response);
          if (response?.error) throw new Error(response.error);

          response?.result && setPhaseProgress(response?.result);
        }
      } catch (error) {
        console.error("Error fetching deepdive phase progress:", error);
      }
    };

    if (
      deepdivePhase &&
      !phaseProgress &&
      !loadingState.creatingRoadmaps &&
      loadingState.loadingDeepdiveRoadmap === "loaded"
    ) {
      fetchPhaseProgress();
    }
  }, [deepdivePhase, phaseProgress, loadingState]);

  if (loadingState.creatingDeepdiveRoadmap)
    return (
      <LoaderWrap>
        <CustomLoader primaryMsg="Creating Your Deep dive Roadmap..." />
      </LoaderWrap>
    );

  if (
    !(
      showGoDeeperBtn ||
      showDeepdiveRoadmap ||
      loadingState.creatingDeepdiveRoadmap
    )
  )
    return null;

  return (
    <Box marginBottom="20px" alignSelf="flex-start">
      {showDeepdiveRoadmap && (
        <Box display="flex" flexDirection="column" gap="16px">
          <Typography
            color="#FEFEFE"
            fontWeight={700}
            fontSize="1.125rem"
            lineHeight="1.875rem"
          >
            Deep Dive
          </Typography>

          {deepdivePhase?.roadmap?.phases?.map(
            ({ id, phaseTitle, overview, tags, sections }) => (
              <CoursePresentationCard
                key={id}
                course={{
                  id: id,
                  learningPrompt: deepdivePhase.roadmap.learningPrompt,
                  phaseType: "godeeper",
                  phaseRoadmapId: deepdivePhase.roadmapId,
                  imageUrl: deepdivePhase.roadmap.image || undefined,
                  courseName: phaseTitle,
                  description: overview,
                  stacks: tags,
                  courseTopics: tags,
                  structure: sections.map((s) => s.title),
                  sections: new Map(sections.map((s, j) => [j + 1, s])),
                }}
                completedCnt={getPhaseProgress(phaseProgress).completed}
                sectionsCnt={getPhaseProgress(phaseProgress).total}
                btnText="Go to phase"
                imageUrl={deepdivePhase.roadmap.image}
              />
            )
          )}
        </Box>
      )}
    </Box>
  );
};

export default RenderDeepDiveSection;

interface RenderDeepDiveSectionProps {
  deepdivePhase: RoadmapListItem | undefined;
  roadmapId: string;
  showDeepdiveRoadmap: any;
}

const LoaderWrap = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "12px 0",
});
